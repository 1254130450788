<template>
  <div>
    <div v-if="shower === true">
      <!-- xs -->
      <v-bottom-sheet v-model="shower" v-if="imageHeight < 500">
        <v-list dense tile>
          <v-list-item>
            <v-list-item-icon class="mt-5">
              <v-icon
                color="red"
                v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
              >
                lock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ file.file_name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-icon class="mr-n12">
                <v-icon color="red" @click="$emit('closecurrentonly')">clear</v-icon>
              </v-list-item-icon>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-flex  
          style="max-height:400px;"
          class="overflow-y-auto">
          <dir v-if="file.editing_status === 'S'">
          </dir>
          <div v-else-if="dataAccountActive.type === 'Business' && file.file_type !== 'folder'">
            <v-list-group :value="false" prepend-icon="visibility" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(item, i) in itemsOpenFile"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- เมนูแก้ไขออนไลน์ -->
            <!-- <div
            v-if="
              (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.data_type.toLowerCase())) && file.file_type !== 'folder'"
               @mouseover="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase())) ? show_cannotedit = true : show_cannotedit = false" 
               @mouseleave="show_cannotedit= false"
            >
            <v-list-item @click="clicker(itemeditFileonline[0].to, itemeditFileonline[0].event, file)" :disabled="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase()))" >
              <v-list-item-icon>
                <v-icon v-text="itemeditFileonline[0].icon"></v-icon>
              </v-list-item-icon>
              <v-badge v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge v-else
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemeditFileonline[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div> -->
            <v-divider class="mb-1"></v-divider>
            <div v-if="file.editing_status === 'N' && ( file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06' )">
              <!-- || file.file_permission.admin_data === 'True' -->
              <v-list-item
                v-for="(item, i) in itemsTagversion"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text)}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- || file.editing_status === 'S' -->
            <div v-if="(file.editing_status === 'Y') && this.dataCitizenProfile.user_id === file.update_by">
              <v-list-item
                v-for="(item, i) in itemsTagversion"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else-if="dataAccountActive.type === 'Citizen' && file.file_type !== 'folder'">
            <v-list-group :value="false" prepend-icon="visibility" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(item, i) in itemsOpenFile"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <!-- เมนูแก้ไขออนไลน์ -->
            <!-- <div
            v-if="
              (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.data_type.toLowerCase())) && file.file_type !== 'folder'"
               @mouseover="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase())) ? show_cannotedit = true : show_cannotedit = false" 
               @mouseleave="show_cannotedit= false"
            >
            <v-list-item @click="clicker(itemeditFileonline[0].to, itemeditFileonline[0].event, file)" :disabled="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase()))" >
              <v-list-item-icon>
                <v-icon v-text="itemeditFileonline[0].icon"></v-icon>
              </v-list-item-icon>
              <v-badge v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge v-else
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemeditFileonline[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div> -->
            <v-divider class="mb-1"></v-divider>
            <div v-if="file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'">
              <!-- || file.file_permission.admin_data === 'True' -->
              <v-list-item
                v-for="(item, i) in itemsTagversion"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <v-list-group :value="false" prepend-icon="mdi-folder-open" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.open") }}</v-list-item-title>
              </template>
              <v-list-item @click="clicker(itemOpenfolder[0].to, itemOpenfolder[0].event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="itemOpenfolder[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemOpenfolder[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="clicker(itemOpenfolder[1].to, itemOpenfolder[1].event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="itemOpenfolder[1].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemOpenfolder[1].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
      
          <!-- หน้าจอ xs -->
          <!-- copyfile -->
          <!-- <div v-if="file.editing_status === 'N'">       
             <v-divider class="mb-1" v-if="file.file_type === 'folder'"></v-divider>
          <v-list-item
            @click="clicker(itemscopy[0].to, itemscopy[0].event, file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon v-text="itemscopy[0].icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(itemscopy[0].text)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-divider class="mb-1" v-if="checkbtncopy == false"></v-divider>
          </div>
           
          <div v-if="checkbtncopy == true && file.editing_status === 'N'" >
          <v-list-item
            @click="clicker(itemscopy[1].to, itemscopy[1].event, file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon v-text="itemscopy[1].icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(itemscopy[1].text)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
             <v-divider class="mb-1"></v-divider>
        </div> -->
          <div>
            <!-- สำหรับกำลังซิงค์ -->
            <v-list-item
              v-if="file.editing_status === 'S'"
              @click="items[1].text === 'currentRClick.addtostared' ? disableByPackage ? '' : clicker(items[1].to, items.event, file) : clicker(items[1].to, items[1].event, file)"
            >
              <v-list-item-icon>
                <v-icon v-text="items[1].icon"> </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="items[1].text === 'currentRClick.addtostared' && file.file_status === 'N'">{{
                  $t(items[1].text)
                }}</v-list-item-title>
                <v-list-item-title v-else-if="items[1].text === 'currentRClick.addtostared' && file.file_status === 'S'">{{
                  $t("currentRClick.removestarred")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{ $t(items[1].text),}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(item, i) in itemsFilter"
              :key="i"
              @click="item.text === 'currentRClick.addtostared' ? disableByPackage ? '' : clicker(item.to, item.event, file) : clicker(item.to, item.event, file)"
              :disabled="item.text === 'currentRClick.addtostared' ? disableByPackage ? '' : checkBusinessDrive(file.file_status) : checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-if="item.text === 'currentRClick.addtostared' && file.file_status === 'N'">{{
                  $t(item.text)
                }}</v-list-item-title>
                <v-list-item-title v-else-if="item.text === 'currentRClick.addtostared' && file.file_status === 'S'">{{
                  $t("currentRClick.removestarred")
                }}</v-list-item-title>
                <v-list-item-title v-else>{{ $t(item.text),}}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay v-if="item.text === 'currentRClick.addtostared'" absolute color="grey lighten-1" :value="disableByPackage">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
            <!-- Paste file to folder / วางไฟล์ลงโฟลเดอร์  -->
            <v-list-item 
            v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type === 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2) && isOpen_pastetofolder" 
            @click="clicker(itemPasteFile_toFolder_[0].to, itemPasteFile_toFolder_[0].event, file)">
                <v-list-item-icon>
                  <v-icon> {{ itemPasteFile_toFolder_[0].icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(itemPasteFile_toFolder_[0].text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item 
            v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type === 'folder' && isOpen_pastetofolder" 
            @click="clicker(itemPasteFile_toFolder_[0].to, itemPasteFile_toFolder_[0].event, file)">
                <v-list-item-icon>
                  <v-icon> {{ itemPasteFile_toFolder_[0].icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(itemPasteFile_toFolder_[0].text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <!-- read file mobile -->
          <div
            v-if="
              (['03', '04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['png', 'pdf', 'jpg'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['png', 'pdf', 'jpg'].includes(file.data_type.toLowerCase()))
            "
          >
            <v-list-item @click="clicker(itemReadFile[0].to, itemReadFile[0].event, file)">
              <v-list-item-icon>
                <v-icon v-text="itemReadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemReadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- คัดลอกไฟล์ (Copy file) (ปิดชั่วคราว) -->
          <!-- <div v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type !== 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2)">
            <v-list-item v-for="(item, i) in itemCopyFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type !== 'folder'" >
            <v-list-item v-for="(item, i) in itemCopyFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div> -->
          <!-- Edit mobile -->
          <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
            <v-list-group
              :value="false"
              prepend-icon="create"
              no-action
              v-if="role_level === 'True' || file.file_owner_eng === 'Me'"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.editmenu") }}</v-list-item-title>
              </template>
              <v-list-item @click="clicker(itemEdit[0].to, itemEdit[0].event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="itemEdit[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="clicker(itemEdit[1].to, itemEdit[1].event, file)"
                v-if="file.file_type !== 'folder' && (checkAdminFolder === 'True' || (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'))"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="itemEdit[1].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[1].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
            <v-list-group
              :value="false"
              prepend-icon="create"
              no-action
              v-if="file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'"
            >
            <!-- v-if="file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'|| file.file_owner_eng === 'Me'" -->
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.editmenu") }}</v-list-item-title>
              </template>
              <v-list-item @click="clicker(itemEdit[0].to, itemEdit[0].event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="itemEdit[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="clicker(itemEdit[1].to, itemEdit[1].event, file)"
                v-if="file.file_type !== 'folder' && (checkAdminFolder === 'True' || (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'))"
              >
                <v-list-item-icon>
                  <v-icon medium v-text="itemEdit[1].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[1].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <!-- || file.editing_status === 'S' -->
          <div v-else-if="dataAccountActive.type === 'Business' && (file.editing_status === 'Y')">
            <v-list
              v-if="
                (checkAdminFolder === 'True' || file.update_by === this.dataCitizenProfile.user_id || file.file_owner_th === 'ฉัน') && file.file_type !== 'folder'
              "
            >
              <!-- แก้ไขไฟล์และปลดล็อก -->
              <v-list-item
                @click="clicker(itemEdit[2].to, itemEdit[2].event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="itemEdit[2].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[2].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item @click="clicker(itemEdit[0].to, itemEdit[0].event, file)">
              <v-list-item-icon>
                <v-icon medium v-text="itemEdit[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemEdit[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- Send mobile -->
          <div v-if="(file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06') && file.editing_status === 'N' && file.file_type !== 'folder'">
            <v-list-group :value="false" prepend-icon="mdi-send" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.sendfilemenu") }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(item, index) in fn_itemfilter('sendto')"
                :key="index"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <!-- Share mobile menu -->
          <div v-if="file.file_permission_2 !== '01' && file.file_permission_2 !== '02' && file.file_permission_2 !== '03' && file.editing_status === 'N'">
            <v-list-group :value="false" prepend-icon="mdi-share" no-action>
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.sharefilemenu") }}</v-list-item-title>
              </template>
              <v-list-item
                v-for="(item, index) in fn_itemfilter('share')"
                :key="index"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
          <!-- Password mobile menu -->
          <div v-if="((file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06') && file.editing_status === 'N')">
             <!-- || file.file_owner_eng === 'Me' || file.file_owner_th === 'ฉัน' -->
            <v-list-group :value="false" :ripple="!disableByPackage" prepend-icon="lock" no-action :disabled="disableByPackage">
              <template v-slot:activator>
                <v-list-item-title>{{ $t("currentRClick.pwdmenu") }}</v-list-item-title>
                <v-expand-transition>
                  <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                    <v-icon>mdi-lock</v-icon>
                  </v-overlay>
                </v-expand-transition>
              </template>
              <!-- ตั้งรหัส -->
              <v-list-item
                @click="clicker(itemCheckpassword[0].to, itemCheckpassword[0].event, file)"
                v-if="
                  (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06' || file.file_owner_eng === 'Me' || file.file_owner_th === 'ฉัน') &&
                  (file.status_lock_folder === 'N' || file.status_lock_file === 'N' || file.status_lock_data === 'N')
                "
              >
                <v-list-item-icon>
                  <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemCheckpassword[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- แก้ไขรหัส -->
              <v-list-item
                @click="clicker(itemCheckpassword[1].to, itemCheckpassword[1].event, file)"
                v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
              >
                <v-list-item-icon>
                  <v-icon v-text="itemCheckpassword[1].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemCheckpassword[1].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- ลืมรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[3].to, itemCheckpassword[3].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[3].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[3].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              <!-- ลบรหัสผ่าน -->
              <v-list-item
                @click="clicker(itemCheckpassword[2].to, itemCheckpassword[2].event, file)"
                v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
              >
                <v-list-item-icon>
                  <v-icon v-text="itemCheckpassword[2].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemCheckpassword[2].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </div>
           <!-- //! Menu Copy lg ทำสำเนา ปิดชั่วคราว -->
           <!-- <div v-if="file.file_type !== 'folder' && ['04', '05', '06'].includes(file.file_permission_2) && file.editing_status === 'N'">
            <v-list-item v-for="(item, i) in itemCopyFile" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
           </div> -->

          <!-- setexpiredfile mobile -->
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
              file.editing_status === 'N' && (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06')
            "
          >
          <!-- (role_level === 'True' ||
              (checkAdminFolder === 'True' || -->
           <!-- || file.file_owner_eng === 'Me' -->
            <v-list-item v-for="(item, i) in itemsetexpiredfile" :key="i" @click="disableByPackage ? '' : clicker(item.to, item.event, file)" :disabled="disableByPackage">
              <v-list-item-icon>
                <v-icon medium v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>

          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item v-for="(item, i) in itemsetexpiredfile" :key="i" @click="disableByPackage ? '' : clicker(item.to, item.event, file)" :disabled="disableByPackage">
              <v-list-item-icon>
                <v-icon medium v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>
          <!-- Delete mobile -->
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
                file.editing_status === 'N' &&
                (checkAdminFolder === 'True' || file.file_permission.delete === 'True') 
            "
          >
            <v-list-item
              @click="role_level === 'True' ? clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file) : disableByPackage ? '' : clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file) "
              :disabled="checkBusinessDrive(file.file_status) || role_level === 'True' ? false : disableByPackage ? true : false"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="role_level === 'True' ? false : disableByPackage ? true : false">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
            <v-list-item
              v-if="fileLength > 1"
              @click="role_level === 'True' ? clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file) : disableByPackage ? '' : clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file) "
              :disabled="checkBusinessDrive(file.file_status) || role_level === 'True' ? false : disableByPackage ? true : false"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="role_level === 'True' ? false : disableByPackage ? true : false">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item
              @click="clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="fileLength > 1"
              @click="clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <!-- Permission  mobile-->
          <div>
            <v-list-item
              v-if="
                dataAccountActive.type === 'Business' &&
                  file.editing_status === 'N' &&
                  checkPermission === true &&
                  (checkAdminFolder === 'True' || role_level === 'True')
              "
              @click="clicker(itemPermission[0].to, itemPermission[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon medium v-text="itemPermission[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemPermission[0].text),}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- Relationfile mobile-->
          <!-- <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
          <div
            v-if="role_level === 'true' || file.file_owner_eng === 'Me'"
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              v-for="(item, i) in itemRelationfileFilter"
              :key="i"
              @click="clicker(item.to, item.event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
              file.editing_status === 'N' &&
              this.checksetpermission === true &&
              file.file_permission_2 !== '01'
            "
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              v-for="(item, i) in itemRelationfileFilter"
              :key="i"
              @click="clicker(item.to, item.event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          </div> -->
          <!-- download mobile-->
          <!-- สำหรับกำลังซิงค์ -->
          <dir v-if="file.editing_status === 'S'">
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </dir>
          <!-- ตั้งค่า profile ทันตแพทย์ -->
          <div v-if="feature_setprofile === true">
          <v-list-item
          v-if="
                dataAccountActive.type === 'Business' && file.editing_status === 'N' && checkPermission === true &&(checkAdminFolder === 'True' || role_level === 'True') && (file.file_type === 'png' || file.file_type === 'jpg' || file.file_type === 'jpeg')"
                @click="clicker(itemssetprofile[0].to, itemssetprofile[0].event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="itemssetprofile[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemssetprofile[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          <div
            v-if="
              dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0
            "
          >
            <div
              v-if="
                ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'pdf'].includes(file.file_type.toLowerCase()) &&
                file.editing_status === 'N' &&
                (file.file_permission_2 !== '01')
              "
            >
              <v-divider class="mb-1"></v-divider>
              <!-- PDF -->
              <v-list-item @click="fn_printPDF_single()">
                <v-list-item-icon>
                  <v-icon v-text="itemprintPDF[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemprintPDF[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- <v-divider class="mb-1"></v-divider> -->
            <!-- keydetail -->
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- download -->
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="file.file_owner_eng === 'Me' || role_level === 'True'"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- download all -->
            <v-list-item
              v-if="fileLength > 1 && (role_level === 'True' || file.file_owner_eng === 'Me')"
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div
            v-else-if="
              dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.file_permission_2 !== '01' && file.status_expired != 0
            "
          >
            <div
              v-if="
                ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'pdf'].includes(file.file_type.toLowerCase()) &&
                file.editing_status === 'N' &&
                (file.file_permission_2 !== '01')
              "
            >
              <v-divider class="mb-1"></v-divider>
              <!-- PDF -->
              <v-list-item @click="fn_printPDF_single()">
                <v-list-item-icon>
                  <v-icon v-text="itemprintPDF[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemprintPDF[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <v-divider class="mb-1"></v-divider>
            <!-- keydetail -->
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- download -->
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="file.file_permission_2 !== '01'"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- download all -->
            <v-list-item
              v-if="fileLength > 1 && file.file_permission_2 === '01'"
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- || file.editing_status === 'S' -->
          <div
            v-else-if="
              dataAccountActive.type === 'Business' &&
             ( file.editing_status === 'Y' ) &&
              (this.dataCitizenProfile.user_id === file.update_by || file.file_permission_2 === '06')
            "
          >
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="fileLength > 1"
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-flex>

          <!-- </v-list-item-group> -->
        </v-list>
      </v-bottom-sheet>
      <!-- lg -->
      <!-- v-model="shower" -->
      <v-menu
        v-model="shower"
        v-else
        :elevation="0"
        :position-x="x"
        :position-y="y"
        top
        offset-y
        :close-on-content-click="false"
        close-on-click
        transition="slide-y-transition"
        content-class="menu-content"
      >
        <v-list dense tile>
          <v-list-item>
            <v-list-item-icon
              class="ml-1 mr-6"
              v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
            >
              <v-icon small color="red">lock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                file.file_name.length >= 30 ? file.file_name.substring(0, 30) + "..." : file.file_name
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="file.file_type != 'folder' && file.version_tag == 'True'">
              <!-- || file.editing_status === 'S' -->
              <v-icon
                @click="opendialog_tagversion = true"
                :disabled="(file.editing_status === 'Y') && file.update_by !== dataCitizenProfile.user_id"
                >mdi-tag-multiple</v-icon
              >
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <!-- <v-list-item-group v-model="item" color="primary"> -->
          <!-- OpenFile lg-->
          <div v-if="file.editing_status === 'S'">
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.file_type !== 'folder'">
            <div>
              <v-menu offset-x top open-on-hover :left="fn_checkpositionmenu()">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-icon>
                      <v-icon>visibility</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon medium>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in itemsOpenFile"
                    :key="i"
                    @click="clicker(item.to, item.event, file)"
                    :disabled="checkBusinessDrive(file.file_status)"
                  >
                    <v-list-item-icon>
                      <v-icon medium v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!-- เมนูแก้ไขออนไลน์ -->
            <!-- <div
            v-if="
              (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.data_type.toLowerCase())) && file.file_type !== 'folder'"
               @mouseover="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase())) ? show_cannotedit = true : show_cannotedit = false" 
               @mouseleave="show_cannotedit= false"
          >
            <v-list-item @click="clicker(itemeditFileonline[0].to, itemeditFileonline[0].event, file)" :disabled="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase()))" >
              <v-list-item-icon>
                <v-icon v-text="itemeditFileonline[0].icon"></v-icon>
              </v-list-item-icon>
              <v-badge v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge v-else
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemeditFileonline[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div> -->
            <v-divider class="mb-1"></v-divider>

            <div v-if="file.editing_status === 'N' && (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06')">
              <!-- file.file_permission.admin_data === 'True' ||
                    file.file_owner_eng === 'Me') -->
              <v-list-item v-for="(item, index) in itemsTagversion" :key="index" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <!-- || file.editing_status === 'S' -->
            <div v-if="(file.editing_status === 'Y') && this.dataCitizenProfile.user_id === file.update_by">
              <v-list-item v-for="(item, index) in itemsTagversion" :key="index" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <!-- ไฟล์ OpenFile citizen lg-->
          <div v-else-if="dataAccountActive.type === 'Citizen' && file.file_type !== 'folder'">
            <div>
              <v-menu offset-x top open-on-hover :left="fn_checkpositionmenu()">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-icon>
                      <v-icon medium>visibility</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon medium>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <v-list dense tile>
                  <v-list-item
                    v-for="(item, i) in itemsOpenFile"
                    :key="i"
                    @click="clicker(item.to, item.event, file)"
                    :disabled="checkBusinessDrive(file.file_status)"
                  >
                    <v-list-item-icon>
                      <v-icon medium v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <!-- เมนูแก้ไขออนไลน์ -->
            <!-- <div
            v-if="
              (['04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['docx','doc','xlsx','xls','pptx','ppt'].includes(file.data_type.toLowerCase())) && file.file_type !== 'folder'"
               @mouseover="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase())) ? show_cannotedit = true : show_cannotedit = false" 
               @mouseleave="show_cannotedit= false"
          >
            <v-list-item @click="clicker(itemeditFileonline[0].to, itemeditFileonline[0].event, file)" :disabled="(file.file_type && ['doc','xls','ppt'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['doc','xls','ppt'].includes(file.data_type.toLowerCase()))" >
              <v-list-item-icon>
                <v-icon v-text="itemeditFileonline[0].icon"></v-icon>
              </v-list-item-icon>
              <v-badge v-if="$t('default') === 'th'"
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
                <v-badge v-else
                  style="z-index:10000;bottom:-45px;left:-70px;"
                  :value="show_cannotedit"
                  :content="$t('balloon.editfileonline')"
                  transition="slide-y-transition"
                ></v-badge>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemeditFileonline[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </div> -->
            <v-divider class="mb-1"></v-divider>
            <!-- ดูไฟล์ Tag lg-->
            <div v-if="(file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06')">
              <!-- file.file_permission.admin_data === 'True' ||
                  file.file_owner_eng === 'Me' -->
              <v-list-item v-for="(item, index) in itemsTagversion" :key="index" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon medium v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <div>
              <v-menu offset-x top open-on-hover :left="fn_checkpositionmenu()">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-icon>
                      <v-icon medium>mdi-folder-open</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t("currentRClick.open") }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon medium>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
                <v-list dense tile>
                  <v-list-item
                    @click="clicker(itemOpenfolder[0].to, itemOpenfolder[0].event, file)"
                    :disabled="checkBusinessDrive(file.file_status)"
                  >
                    <v-list-item-icon>
                      <v-icon medium v-text="itemOpenfolder[0].icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(itemOpenfolder[0].text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="clicker(itemOpenfolder[1].to, itemOpenfolder[1].event, file)"
                    :disabled="checkBusinessDrive(file.file_status)"
                  >
                    <v-list-item-icon>
                      <v-icon medium v-text="itemOpenfolder[1].icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ $t(itemOpenfolder[1].text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <v-divider class="mb-1"></v-divider>
          </div>

          <!-- <div v-else>
           <v-list-item
            @click="checklockfile(file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon medium v-text="itemsOpenFile[0].icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(itemsOpenFile[0].text)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div> -->

          <!-- copyfile lg -->
          <!-- <div v-if="file.editing_status === 'N'">       
             <v-divider class="mb-1" v-if="file.file_type === 'folder'"></v-divider>
          <v-list-item
            @click="clicker(itemscopy[0].to, itemscopy[0].event, file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon v-text="itemscopy[0].icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(itemscopy[0].text)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
           <v-divider class="mb-1" v-if="checkbtncopy == false"></v-divider>
        </div>

        <div  v-if="file.editing_status === 'N' && checkbtncopy == true">
          <v-list-item
            @click="clicker(itemscopy[1].to, itemscopy[1].event, file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon v-text="itemscopy[1].icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(itemscopy[1].text)}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
             <v-divider class="mb-1"></v-divider>
        </div> -->

          <!-- menu starred movefile showinfolder -->
          <!-- สำหรับกำลังซิงค์ -->
          <v-list-item
            v-if="file.editing_status === 'S'"
            @click="items[1].text === 'currentRClick.addtostared' ? disableByPackage ? '' : clicker(items[1].to, items.event, file) : clicker(items[1].to, items[1].event, file)"
          >
            <v-list-item-icon>
              <v-icon v-text="items[1].icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="items[1].text === 'currentRClick.addtostared' && file.file_status === 'N'">{{
                $t(items[1].text)
              }}</v-list-item-title>
              <v-list-item-title v-else-if="items[1].text === 'currentRClick.addtostared' && file.file_status === 'S'">{{
                $t("currentRClick.removestarred")
              }}</v-list-item-title>
              <v-list-item-title v-else>{{ $t(items[1].text),}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in itemsFilter"
            :key="i"
            @click="item.text === 'currentRClick.addtostared' ? disableByPackage ? '' : clicker(item.to, item.event, file) : clicker(item.to, item.event, file)"
            :disabled="item.text === 'currentRClick.addtostared' ? disableByPackage ? '' : checkBusinessDrive(file.file_status) : checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"> </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="item.text === 'currentRClick.addtostared' && file.file_status === 'N'">{{
                $t(item.text)
              }}</v-list-item-title>
              <v-list-item-title v-else-if="item.text === 'currentRClick.addtostared' && file.file_status === 'S'">{{
                $t("currentRClick.removestarred")
              }}</v-list-item-title>
              <v-list-item-title v-else>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-expand-transition>
              <v-overlay v-if="item.text === 'currentRClick.addtostared'" absolute color="grey lighten-1" :value="disableByPackage">
                <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                  </v-tooltip>
              </v-overlay>
            </v-expand-transition>
          </v-list-item>
          <!-- read file lg -->
          <div
            v-if="              
              (['03', '04', '05', '06'].includes(file.file_permission_2)) &&
              (file.file_type && ['png', 'pdf', 'jpg'].includes(file.file_type.toLowerCase()) || 
               file.data_type && ['png', 'pdf', 'jpg'].includes(file.data_type.toLowerCase())) &&
               file.editing_status !== 'Y'
            "
          >
            <v-list-item @click="clicker(itemReadFile[0].to, itemReadFile[0].event, file)">
              <v-list-item-icon>
                <v-icon v-text="itemReadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemReadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- คัดลอกไฟล์ (Copy file) (ปิดชั่วคราว) -->
          <!-- <div v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type !== 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2)">
            <v-list-item v-for="(item, i) in itemCopyFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type !== 'folder'" >
            <v-list-item v-for="(item, i) in itemCopyFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div> -->
          <!-- วางไฟล์ -->
          <div v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type !== 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2) && isOpen_pastefile">
            <v-list-item v-for="(item, i) in itemPasteFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type !== 'folder' && isOpen_pastefile" >
            <v-list-item v-for="(item, i) in itemPasteFile_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <!-- วางไฟล์ใน folder -->
          <div v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type === 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2) && isOpen_pastetofolder">
            <v-list-item v-for="(item, i) in itemPasteFile_toFolder_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type === 'folder' && isOpen_pastetofolder" >
            <v-list-item v-for="(item, i) in itemPasteFile_toFolder_" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <!-- Menu Edit lg-->
          <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
            <!-- <v-list  v-if="role_level === 'True' || file.file_owner_eng === 'Me'">
              <v-list-item
                @click="clicker(fn_itemfilter('edit')[0].to, fn_itemfilter('edit')[0].event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="fn_itemfilter('edit')[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(fn_itemfilter("edit")[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="role_level === 'True' || file.file_owner_eng === 'Me'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>create</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.editmenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <!-- @click="clicker(fn_itemfilter('edit')[0].to, fn_itemfilter('edit')[0].event, file)" -->
                <v-list-item
                  @click="clicker(itemEdit[0].to, itemEdit[0].event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon medium v-text="itemEdit[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemEdit[0].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="(file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06')"
            >
             <!-- || file.file_owner_eng === 'Me' -->
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>create</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.editmenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <v-list-item
                  @click="clicker(fn_itemfilter('edit')[0].to, fn_itemfilter('edit')[0].event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon medium v-text="itemEdit[0].icon"></v-icon>
                    <!-- <v-icon v-text="fn_itemfilter('edit')[0].icon"></v-icon> -->
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemEdit[0].text) }}</v-list-item-title>
                    <!-- <v-list-item-title>{{ $t(fn_itemfilter("edit")[0].text) }}</v-list-item-title> -->
                  </v-list-item-content>
                </v-list-item>
                <!-- แก้ไขไฟล์และปลดล็อก -->
                <v-list-item
                  v-if="file.file_type !== 'folder' && (checkAdminFolder === 'True' || (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06'))"
                  @click="clicker(fn_itemfilter('edit')[1].to, fn_itemfilter('edit')[1].event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="fn_itemfilter('edit')[1].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(fn_itemfilter("edit")[1].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- ||  file.editing_status === 'S' -->
          <div v-else-if="dataAccountActive.type === 'Business' && (file.editing_status === 'Y')">
            <v-list
              v-if="
                (checkAdminFolder === 'True' || file.update_by === this.dataCitizenProfile.user_id || file.file_owner_th === 'ฉัน') && file.file_type !== 'folder'
              "
            >
              <!-- แก้ไขไฟล์และปลดล็อก -->
              <v-list-item
                @click="clicker(itemEdit[2].to, itemEdit[2].event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="itemEdit[2].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(itemEdit[2].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list>
              <v-list-item
                @click="clicker(fn_itemfilter('edit')[0].to, fn_itemfilter('edit')[0].event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="fn_itemfilter('edit')[0].icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(fn_itemfilter("edit")[0].text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
          <div>
            <!-- Menu Send File lg-->
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="(file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06') && file.editing_status === 'N' && file.file_type !== 'folder'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>mdi-send</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.sendfilemenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <v-list-item
                  v-for="(item, index) in fn_itemfilter('sendto')"
                  :key="index"
                  @click="clicker(item.to, item.event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
            <!-- //! Menu Share File lg-->
          <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="file.file_permission_2 !== '01' && file.file_permission_2 !== '02' && file.file_permission_2 !== '03' && role_level === 'true' || file.file_owner_eng === 'Me'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>mdi-share</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.sharefilemenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <v-list-item
                  v-for="(item, index) in fn_itemfilter('share')"
                  :key="index"
                  @click="clicker(item.to, item.event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="file.file_permission_2 !== '01' && file.file_permission_2 !== '02' && file.file_permission_2 !== '03' && file.editing_status === 'N'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>mdi-share</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.sharefilemenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <v-list-item
                  v-for="(item, index) in fn_itemfilter('share')"
                  :key="index"
                  @click="clicker(item.to, item.event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
           <div v-else-if="dataAccountActive.type === 'Citizen'">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-icon>
                    <v-icon>mdi-share</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.sharefilemenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-list dense tile>
                <v-list-item
                  v-for="(item, index) in fn_itemfilter('share')"
                  :key="index"
                  @click="clicker(item.to, item.event, file)"
                  :disabled="checkBusinessDrive(file.file_status)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t(item.text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
            <!-- //! Menu Password lg-->
          <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="role_level === 'true' || file.file_owner_eng === 'Me'"
              :disabled="disableByPackage"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" :disabled="disableByPackage">
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.pwdmenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                  <v-expand-transition>
                    <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                    </v-overlay>
                  </v-expand-transition>
                </v-list-item>
              </template>
                  
              <!-- ตั้งรหัส -->
              <v-list dense tile>
                <v-list-item
                  @click="clicker(itemCheckpassword[0].to, itemCheckpassword[0].event, file)"
                  v-if="
                    (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06') &&
                    (file.status_lock_folder === 'N' || file.status_lock_file === 'N' || file.status_lock_data === 'N')
                  "
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[0].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- แก้ไขรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[1].to, itemCheckpassword[1].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[1].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[1].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- ลืมรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[3].to, itemCheckpassword[3].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[3].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[3].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- ลบรหัสผ่าน -->
                <v-list-item
                  @click="clicker(itemCheckpassword[2].to, itemCheckpassword[2].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[2].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[2].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              v-if="((file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06') && file.editing_status === 'N')"
              :disabled="disableByPackage"
            >
              <!-- || file.file_owner_eng === 'Me' || file.file_owner_th === 'ฉัน' -->
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" :disabled="disableByPackage">
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.pwdmenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                  <v-expand-transition>
                    <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                    </v-overlay>
                  </v-expand-transition>
                </v-list-item>
              </template>
                  
              <!-- ตั้งรหัส -->
              <v-list dense tile>
                <v-list-item
                  @click="clicker(itemCheckpassword[0].to, itemCheckpassword[0].event, file)"
                  v-if="
                    (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06' || file.file_owner_eng === 'Me' || file.file_owner_th === 'ฉัน') &&
                    (file.status_lock_folder === 'N' || file.status_lock_file === 'N' || file.status_lock_data === 'N')
                  "
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[0].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- แก้ไขรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[1].to, itemCheckpassword[1].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[1].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[1].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- ลืมรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[3].to, itemCheckpassword[3].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[3].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[3].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- ลบรหัสผ่าน -->
                <v-list-item
                  @click="clicker(itemCheckpassword[2].to, itemCheckpassword[2].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[2].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[2].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-else-if="dataAccountActive.type === 'Citizen'">
            <v-menu
              offset-x
              top
              open-on-hover
              :left="fn_checkpositionmenu()"
              :disabled="disableByPackage"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" :disabled="disableByPackage">
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t("currentRClick.pwdmenu") }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-list-item-action>
                  <v-expand-transition>
                    <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                      <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                    </v-overlay>
                  </v-expand-transition>
                </v-list-item>
              </template>
              <!-- ตั้งรหัส -->
              <v-list dense tile>
                <v-list-item
                  @click="clicker(itemCheckpassword[0].to, itemCheckpassword[0].event, file)"
                  v-if="
                    (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06' || file.file_owner_eng === 'Me' || file.file_owner_th === 'ฉัน') &&
                    (file.status_lock_folder === 'N' || file.status_lock_file === 'N' || file.status_lock_data === 'N')
                  "
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[0].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[0].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- แก้ไขรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[1].to, itemCheckpassword[1].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[1].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[1].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- ลืมรหัส -->
                <v-list-item
                  @click="clicker(itemCheckpassword[3].to, itemCheckpassword[3].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[3].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[3].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- ลบรหัสผ่าน -->
                <v-list-item
                  @click="clicker(itemCheckpassword[2].to, itemCheckpassword[2].event, file)"
                  v-if="file.status_lock_folder === 'Y' || file.status_lock_file === 'Y' || file.status_lock_data === 'Y'"
                >
                  <v-list-item-icon>
                    <v-icon v-text="itemCheckpassword[2].icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(itemCheckpassword[2].text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <!-- //! Menu Copy lg ทำสำเนา (ปิดชั่วคราว) -->
          <!-- <div v-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Business' && file.file_type !== 'folder' && ['03', '04', '05', '06'].includes(file.file_permission_2)">
            <v-list-item v-for="(item, i) in itemCopyFile" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="!route_name_restrict.includes($route.name) && dataAccountActive.type === 'Citizen' && file.file_type !== 'folder'">
            <v-list-item v-for="(item, i) in itemCopyFile" :key="i" @click="clicker(item.to, item.event, file)">
                <v-list-item-icon>
                  <v-icon> {{ item.icon }} </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
          </div> -->
          <!-- ตั้งค่าวันหมดอายุไฟล์ lg -->
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
                file.editing_status === 'N' && ( 
                ( (file.file_permission_2 === '04' || file.file_permission_2 === '05' || file.file_permission_2 === '06')))
            "
          >
             <!--role_level === 'True' || checkAdminFolder === 'True' ||   || file.file_owner_eng === 'Me' -->
            <v-list-item v-for="(item, i) in itemsetexpiredfile" :key="i" @click="disableByPackage ? '' : clicker(item.to, item.event, file)" :disabled="disableByPackage">
              <v-list-item-icon>
                <v-icon medium v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                  <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item v-for="(item, i) in itemsetexpiredfile" :key="i" @click="disableByPackage ? '' : clicker(item.to, item.event, file)" :disabled="disableByPackage">
              <v-list-item-icon>
                <v-icon medium v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                  <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                              >mdi-lock</v-icon>
                            </template>
                              <span>{{$t('balloon.lock_function')}}</span>
                  </v-tooltip>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>
          <!-- delete business -->
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
                file.editing_status === 'N' &&
                (checkAdminFolder === 'True' || (file.file_permission_2 === '05' || file.file_permission_2 === '06' ))
            "
          >
            <v-list-item
              @click="role_level === 'True' ? clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file) : disableByPackage ? '' : clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file) "
              :disabled="checkBusinessDrive(file.file_status) || role_level === 'True' ? false : disableByPackage ? true : false"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="role_level === 'True' ? false : disableByPackage ? true : false">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
            <v-list-item
              v-if="fileLength > 1"
              @click="role_level === 'True' ? clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file) : disableByPackage ? '' : clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file) "
              :disabled="checkBusinessDrive(file.file_status) || role_level === 'True' ? false : disableByPackage ? true : false"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
              <v-expand-transition>
                <v-overlay absolute color="grey lighten-1" :value="role_level === 'True' ? false : disableByPackage ? true : false">
                  <v-icon>mdi-lock</v-icon>
                </v-overlay>
              </v-expand-transition>
            </v-list-item>
          </div>
          <!-- ลบ หรือนำลงถังขยะ lg -->
          <div v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item
              @click="clicker(itemsRemoveFile[0].to, itemsRemoveFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
             
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="fileLength > 1"
              @click="clicker(itemsRemoveFile[1].to, itemsRemoveFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsRemoveFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsRemoveFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- PDF lg-->
          <div
            v-if="
              ['jpeg', 'jpg', 'png', 'gif', 'tiff', 'pdf'].includes(file.file_type.toLowerCase()) &&
              file.editing_status === 'N' &&
              (file.file_permission_2 !== '01')
            "
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item @click="fn_printPDF_single()">
              <v-list-item-icon>
                <v-icon v-text="itemprintPDF[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemprintPDF[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- กำหนดสิทธิ์ lg-->
          <div>
            <v-list-item
              v-if="
                dataAccountActive.type === 'Business' && file.editing_status === 'N' && checkPermission === true && ( checkAdminFolder === 'True' || role_level === 'True')
              "
              @click="clicker(itemPermission[0].to, itemPermission[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon medium v-text="itemPermission[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemPermission[0].text),}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- เอกสารที่เกี่ยวข้อง lg -->
          <!-- <div v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0">
          <div
            v-if="role_level === 'true' || file.file_owner_eng === 'Me'"
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              v-for="(item, i) in itemRelationfileFilter"
              :key="i"
              @click="clicker(item.to, item.event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          </div>
          <div v-else-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired != 0">
          <div
            v-if="
              dataAccountActive.type === 'Business' &&
              file.editing_status === 'N' &&
              this.checksetpermission === true &&
              file.file_permission_2 !== '01' 
            "
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              v-for="(item, i) in itemRelationfileFilter"
              :key="i"
              @click="clicker(item.to, item.event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          </div> -->

          <!-- ตั้งค่า profile ทันตแพทย์ -->
          <div v-if="feature_setprofile === true">
            <div
              v-if="dataAccountActive.type === 'Business' && file.editing_status === 'N' && checkPermission === true &&(checkAdminFolder === 'True' || role_level === 'True') && (file.file_type === 'png' || file.file_type === 'jpg' || file.file_type === 'jpeg')">
              <v-list-item
                v-for="(item, i) in itemssetprofile"
                :key="i"
                @click="clicker(item.to, item.event, file)"
                :disabled="checkBusinessDrive(file.file_status)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          
          <!-- รายละเอียดและดาวน์โหลด lg -->
          <!-- สำหรับกำลังซิงค์ -->
          <div v-if="file.editing_status === 'S'">
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div
            v-else-if="
              dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.status_expired == 0
            "
          >
            <!-- <v-divider class="mb-1"></v-divider> -->
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
            v-for="(item, i) in itemsDownloadFile"
            :key="i"
            @click="clicker(item.to,item.event,file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon >
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="file.file_owner_eng === 'Me' || role_level === 'True'"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="fileLength > 1 && (role_level === 'True' || file.file_owner_eng === 'Me')"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div
            v-else-if="
              dataAccountActive.type === 'Business' && file.editing_status === 'N' && file.file_permission_2 !== '01' && file.status_expired != 0
            "
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
            v-for="(item, i) in itemsDownloadFile"
            :key="i"
            @click="clicker(item.to,item.event,file)"
            :disabled="checkBusinessDrive(file.file_status)"
          >
            <v-list-item-icon >
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content >
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>-->
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="file.file_permission_2 !== '01'"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="fileLength > 1 && file.file_permission_2 !== '01'"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- || file.editing_status === 'S' -->
          <div
            v-else-if="
              dataAccountActive.type === 'Business' &&
                (file.editing_status === 'Y') &&
                (this.dataCitizenProfile.user_id === file.update_by || file.file_permission_2 === '06')
            "
          >
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div v-else-if="dataAccountActive.type === 'Citizen'">
            <v-divider class="mb-1"></v-divider>
            <v-list-item
              @click="clicker(itemsDownloadFile[2].to, itemsDownloadFile[2].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[2].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[2].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="clicker(itemsDownloadFile[0].to, itemsDownloadFile[0].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[0].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[0].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              @click="clicker(itemsDownloadFile[1].to, itemsDownloadFile[1].event, file)"
              :disabled="checkBusinessDrive(file.file_status)"
              v-if="fileLength > 1"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[1].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[1].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <!-- ตั้งค่าคำขอดาวน์โหลด -->
          <!-- <v-divider class="mb-1"></v-divider> -->
          <div v-if="dataAccountActive.type === 'Business'">
            <v-list-item
              v-if="file.status_setting_request_download === 'Y' && file.file_owner_eng === 'Me'"
              @click="clicker(itemsDownloadFile[3].to, itemsDownloadFile[3].event, file)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[3].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[3].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else-if="file.status_request_download === 'Y' && file.file_owner_eng  !== 'Me'"
              @click="clicker(itemsDownloadFile[4].to, itemsDownloadFile[3].event, file)"
            >
              <v-list-item-icon>
                <v-icon v-text="itemsDownloadFile[4].icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(itemsDownloadFile[4].text) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </div>
    <!-- snackbardowload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowloadfile" bottom color="white" :timeout="timeoutdowload">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="snackbardowloadfile = false" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title>{{ datetime }}</v-list-item-title>
            </v-list-item-content>
            <v-progress-circular
              :rotate="90"
              :size="35"
              :width="3"
              :value="percentCompleted"
              color="teal"
              style="margin-left:25px"
              >&nbsp; {{ percentCompleted }}</v-progress-circular
            >
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>
    <!-- multiple snackbardownload -->
    <div class="text-center ma-2">
      <v-snackbar v-model="snackbardowload" bottom color="white" :timeout="timeout">
        <v-list color="white" style="padding-top: 0px;">
          <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab style="height:40px; width:400px;">
            {{ $t("myinboxPage.dowload.dowloading") }}
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="colsedowload()" fab x-small>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list-item v-for="(item, i) in newlist" :key="i" dense style="height:40px; width:400px;">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <!-- <v-list-item-title>{{namedowload}}</v-list-item-title> -->
            </v-list-item-content>
            <v-progress-circular
              :rotate="-90"
              :size="35"
              :width="3"
              :value="item.value"
              :timeout="timeout"
              color="teal"
              style="margin-left:25px"
            >
              <v-list-item-title v-text="item.value"></v-list-item-title>
            </v-progress-circular>
          </v-list-item>
        </v-list>
      </v-snackbar>
    </div>

    <sharelink
      :show="opensharelink"
      :filedata="file"
      :parentfolder="parentfolder"
      @close="(opensharelink = false)"
      @closesharelink="closeDialogEditname(), (opensharelink = false), $emit('openDrag')"
      @opensharelink="opensharelink = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @opendragselect="$emit('opendragselect')"
      @reload="$emit('reload')"
    ></sharelink>
    <sharelinkuploads
      :show="opensharelinkuploads"
      :filedata="file"
      :parentfolder="parentfolder"
      @close="(opensharelinkuploads = false)"
      @closesharelink="closeDialogEditname(), (opensharelinkuploads = false), $emit('openDrag')"
      @opensharelinkupload="opensharelinkuploads = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @opendragselect="$emit('opendragselect')"
    ></sharelinkuploads>

    <sharefile
      :show="opensharefile"
      :filedata="file"
      @close="(opensharefile = false), $emit('opendragselect')"
      @closesharefile="closeDialogEditname(), (opensharefile = false), $emit('openDrag'), $emit('opendragselect')"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></sharefile>
    <newpreviewfile
      :parentfolder="parentfolder"
      :show="opennewpreviewfile"
      :filedata="file"
      @closenewpreviewfile="(opennewpreviewfile = false), $emit('openDrag')"
      @opennewpreviewfile="opennewpreviewfile = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></newpreviewfile>
    <previewfile
      :show="openpreviewfile"
      :arrayfile="arrayfile"
      :filedata_1="file"
      :parentfolder="parentfolder"
      :param_preview="param_preview"
      @closepreviewfile="(openpreviewfile = false), $emit('openDrag'), $emit('opendragselect'), $emit('status_createpass', false)"
      @openpreviewfile="openpreviewfile = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @reload="$emit('reload')"
    ></previewfile>
    <changename
      :show="openchangename"
      :filedata="file"
      :parentfolder="parentfolder"
      @close="(openchangename = false), $emit('opendragselect')"
      @closechangename="closeDialogEditname(), (openchangename = false), $emit('openDrag')"
      @openchangename="openchangename = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @opendragselect="$emit('opendragselect')"
    ></changename>
    <sendmail
      :show="opensendmail"
      :filedata="file"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname()"
      @closesendmail="(opensendmail = false), $emit('openDrag')"
      @opensendmail="opensendmail = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @opendragselect="$emit('opendragselect')"
    ></sendmail>
    <sendonechat
      :show="opendialogonechat"
      :filedata="file"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname()"
      @closesendonechat="(opendialogonechat = false), $emit('openDrag')"
      @opensendonechat="opendialogonechat = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @closedialog="opendialogonechat = false, $emit('opendragselect')"
      @opendragselect="$emit('opendragselect')"
    ></sendonechat>
    <dl_movefile
      :show="opendlmovefile"
      :filedata="file"
      :parentfoldername="parentfoldername"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname(), (opendlmovefile = false), $emit('openDrag'), $emit('opendragselect')"
      @closedlmovefile="(opendlmovefile = false), $emit('openDrag'), $emit('opendragselect')"
      @opendmovefile="opendlmovefile = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></dl_movefile>
    <keydetail
      :show="openkeydetail"
      :filedata="file"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname(), $emit('opendragselect')"
      @closedkeydetail="(openkeydetail = false), $emit('openDrag'), $emit('opendragselect'), $emit('closedetailfile')"
      @closedreloadkeydetail="(openkeydetail = false), $emit('openDrag'), $emit('reload'), $emit('opendragselect')"
      @openkeydetail="openkeydetail = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></keydetail>
    <relationfile
      :show="openrelationfile"
      :filedata="file"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname()"
      @closedrelationfile="(openrelationfile = false), $emit('openDrag'), $emit('opendragselect')"
      @openrelationfile="openrelationfile = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></relationfile>
    <createrelationfile
      :show="opencreaterelationfile"
      @updatedatafile="closeDialogEditname()"
      @closedialog="opencreaterelationfile = false, $emit('opendragselect')"
      :filedata="file"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></createrelationfile>
    <setpermissionfile
      :show="opensetpermissionfile"
      :filedata="file"
      @closenoreload="(opensetpermissionfile = false), $emit('opendragselect')"
      @closesetPermissionFile="(opensetpermissionfile = false), $emit('reload'), $emit('opendragselect')"
      @opensetPermissionFile="opensetpermissionfile = true"
      @closeDrag="$emit('closeDrag')"
    ></setpermissionfile>
    <createpassword
      :show="opencreatepassword"
      @close="(opencreatepassword = false), $emit('opendragselect'), $emit('status_createpass', false)"
      @closedialog="(opencreatepassword = false), closeDialogEditname(), $emit('opendragselect'), $emit('status_createpass', false)"
      :filedata="file"
    />
    <editpassword 
    :show="openeditpassword" 
    @close="(openeditpassword = false), $emit('opendragselect'), $emit('status_createpass', false)"
    @closedialog="(openeditpassword = false), closeDialogEditname(), $emit('opendragselect'), $emit('status_createpass', false)" 
    :filedata="file" />
    <deletepassword
      :show="opendeletepassword"
      @close="(opendeletepassword = false), $emit('opendragselect'), $emit('status_createpass', false)"
      @closedialog="closeDialogEditname(), (opendeletepassword = false), $emit('opendragselect'), $emit('status_createpass', false)"
      :filedata="file"
    />
    <rightcheckpassword
      :show="opendialog_checklog"
      @closedialog="clickclick"
      :filedata="file"
      :parameter="sendparameter"
      @opendragselect="$emit('opendragselect')"
    />
    <!-- @closepreview="gotodirectory()" -->
    <forgetpassword
      :show="openforgetpassword"
      @closedialog="openforgetpassword = false, $emit('opendragselect'), $emit('status_createpass', false)"
      :filedata="file"
    />
    <!-- :reference="Reference" -->
    <tagversion
      :show="opendialog_tagversion"
      :filedata="file"
      :parentfolder="parentfolder"
      @closedialog="(opendialog_tagversion = false), $emit('closecurrent'), $emit('opendragselect')"
      @closedialognorefresh="opendialog_tagversion = false, $emit('opendragselect')"
    ></tagversion>
    <dialogsnackbardowload
      :show="opensnackbar"
      @closedialog="opensnackbar = false, $emit('opendragselect')"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowload>
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload = false, $emit('opendragselect')"
      :percent="percent"
      :date="datetime"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
    <dialogsnackbardowloadfile
      :show="opensnackbardownloadfile"
      @closedialog="opensnackbardownloadfile = false, $emit('opendragselect')"
      :filedata="file"
      :percen="newpercen"
      :name="namefile"
      :list="new_list"
      :btsdownload="btsdownload"
    ></dialogsnackbardowloadfile>
    <dialogsetexpiredfile
      :show="opensetexpired"
      @closedialog="opensetexpired = false, $emit('opendragselect')"
      :filedata="file"
      @reload="reloadsetexpired()"
    ></dialogsetexpiredfile>
    <dialogcancleexpiredfile
      :show="opencancelexpired"
      @closedialog="opencancelexpired = false, $emit('opendragselect')"
      :filedata="file"
      @reload="reloadsetexpired()"
    ></dialogcancleexpiredfile>
    <dialogconfirmdelete :filedata="file" :show="confirmdelete" @closedialog="confirmdelete = false, $emit('opendragselect')" @reload="fn_redelete()" />
    <checkpassword
      :show="opendialog_checklog_password"
      :parameter="sendparameter"
      @closedialog="clickclick"
      @close="clickclick"
      :filedata="file"
    />
    <dialogsnackbarmultidownload_new
        :show="opendialogmultidownload_new"
        @closedialog="opendialogmultidownload_new = false"
        :data="filedownload_new"
      ></dialogsnackbarmultidownload_new>
    <dialogsetrequest
      :show="opendialogsetrequest"
      @closedialog="opendialogsetrequest = false"
      :filedata="file"
    ></dialogsetrequest>
    <dialogrequesttodownload
      :show="opendialogrequesttodownload"
      @closedialog="opendialogrequesttodownload = false"
      :filedata="file"
    ></dialogrequesttodownload>
    <shortcut :show="openshortcut"></shortcut>
  </div>
</template>

<script>
//import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import axios from "axios";
import CryptoJS from "crypto-js";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";
import gb_sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import  { CancelToken } from 'axios';
//import dialogLoginssoVue from "../optional/dialog-loginsso.vue";
// import dl_movefile from "../optional/dialog-movefile";

const dl_movefile = () => import("../optional/dialog-movefile");
const sendmail = () => import("../optional/dialog-sendmail");
const sendonechat = () => import("../optional/dialog-sendonechat");
const changename = () => import("../optional/dialog-changename");
const previewfile = () => import("../optional/dialog-preview");
const sharelink = () => import("../optional/dialog-sharelink");
const sharefile = () => import("../optional/dialog-sharefile");
const keydetail = () => import("../optional/dialog-keydetail");
const newpreviewfile = () => import("../optional/dialog-newpreview");
const relationfile = () => import("../optional/dialog-relationmyfile");
const createrelationfile = () => import("../optional/dialog-createrelation");
const setpermissionfile = () => import("../optional/dialog-setpermissionfile");
const createpassword = () => import("../optional/dialog-createpassword");
const editpassword = () => import("../optional/dialog-editpassword");
const deletepassword = () => import("../optional/dialog-deletepassword");
const rightcheckpassword = () => import("@/components/optional/dialog-rightclickcheckpassword");
const checkpassword = () => import("@/components/optional/dialog-checkpassword");
const forgetpassword = () => import("@/components/optional/dialog-forgetpassword");
const tagversion = () => import("@/components/optional/dialog-tagversion");
const dialogsnackbardowload = () => import("../optional/dialog-snackbardownload");
const dialogsnackbermultidowload = () => import("../optional/dialog-snackbarmultidowmload");
const dialogsetexpiredfile = () => import("../optional/dialog-setexpiredfile");
const dialogcancleexpiredfile = () => import("../optional/dialog-cancelsettingexpiredfile");
const dialogconfirmdelete = () => import("../optional/dialog-confirmdelete");
const dialogsnackbardowloadfile = () => import("../optional/dialog-snackbardownloadfile");
const shortcut = () => import("../optional/dialog-shortcut");
const dialogsnackbarmultidownload_new = () => import("../optional/dialog-snackbermultidownload-new.vue");
const sharelinkuploads = () => import("../optional/dialog-sharelinkuploads.vue");
const dialogsetrequest = () =>import("../optional/dialog-setrequestdownload.vue");
const dialogrequesttodownload = () =>import("../optional/dialog-requesttodownload.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: [
    "confirm",
    "show",
    "AreaX",
    "AreaY",
    "file",
    "parentfolder",
    "parentfoldername",
    "filemultipledownload",
    "fileLength",
    "checksetpermission",
    "arrayfile",
    "permission_folder",
  ],
  components: {
    shortcut,
    dialogsnackbardowloadfile,
    dialogconfirmdelete,
    checkpassword,
    changename,
    sendmail,
    dl_movefile,
    sendonechat,
    previewfile,
    sharelink,
    sharefile,
    keydetail,
    newpreviewfile,
    relationfile,
    createrelationfile,
    setpermissionfile,
    createpassword,
    editpassword,
    deletepassword,
    rightcheckpassword,
    forgetpassword,
    dialogsnackbardowload,
    dialogsnackbermultidowload,
    tagversion,
    dialogsetexpiredfile,
    dialogcancleexpiredfile,
    dialogsnackbarmultidownload_new,
    sharelinkuploads,
    dialogsetrequest,
    dialogrequesttodownload
  },
  data: function() {
    return {
      opendialogrequesttodownload:false,
      opendialogsetrequest:false,
      isOpen_pastefile: false,
      isOpen_pastetofolder: false,
      route_name_restrict: ['starfiles'],
      show_cannotedit:false,
      param_preview:"",
      listerror:[],
      filedownload_new:[],
      opendialogmultidownload_new:false,
      openshortcut: false,
      opensnackbardownloadfile: false,
      listfilecopy: [],
      checkbtncopy: false,
      btsdownload: false,
      newdata: [],
      opencancelexpired: false,
      opensetexpired: false,
      opendialog_tagversion: false,
      percent: 0,
      new_list: [],
      namefile: "",
      newpercen: [],
      opensnackbar: false,
      opensnackbarmultidownload: false,
      checkPermission: false,
      sendcurrentfile: [],
      sendparameter: "",
      opendialog_checklog: false,
      opendialog_checklog_password: false,
      opensharelinkuploads: false,
      check_delete_permission: false,
      datetime: "",
      timeoutdowload: 0,
      snackbardowloadfile: false,
      snackbardowload: false,
      newlist: [],
      dataIndex: "",
      datadowload: {},
      listdatadowload: [],
      listdowload: [],
      timeout: 6000,
      percentCompleted: 0,
      namedowload: "",
      itemsTagversion: [
        {
          text: "currentRClick.tagversion",
          icon: "mdi-tag-multiple",
          to: "tagversion",
        },
      ],
      Reference: "",
      itemsetexpiredfile: [
        {
          text: "currentRClick.setexpired",
          icon: "mdi-folder-clock",
          to: "setexpired",
          event: "fn",
        },
      ],
      // itemeditandcancleexpiredFile:[
      //   {
      //     text: "currentRClick.editexpired",
      //     icon: "mdi-folder-clock",
      //     to: "editexpired",
      //     event: "fn"
      //   },
      //   {
      //     text: "currentRClick.cancelexpired",
      //     icon: "mdi-folder-clock",
      //     to: "cancelexpired",
      //     event: "fn"
      //   }
      // ],
      itemsOpenFile: [
        {
          text: "currentRClick.preview",
          icon: "visibility",
          to: "preview",
          event: "fn",
        },
        {
          text: "currentRClick.previewnewtab",
          icon: "visibility",
          to: "previewnewtab",
          event: "fn",
        },
        // {
        //   text: "currentRClick.newpreview",
        //   icon: "visibility",
        //   to: "newpreview",
        //   event: "fn"
        // }
      ],
      itemeditFileonline: [
        {
          text: "currentRClick.editfileonline",
          icon: "mdi-file-document-edit-outline",
          to: "editfileonline",
          event: "fn",
        },
      ],
      itemsRemoveFile: [
        {
          text: "currentRClick.movetotrash",
          icon: "delete",
          to: "trash",
          event: "fn",
          owneronly: true,
          // edit:true
        },
        {
          text: "currentRClick.alldelete",
          icon: "delete_forever",
          to: "alltrash",
          event: "fn",
          owneronly: true,
        },
      ],
      itemCheckpassword: [
        {
          text: "currentRClick.setpassword",
          icon: "lock",
          to: "createpassword",
          event: "fn",
          owneronly: true,
        },
        {
          text: "currentRClick.editpassword",
          icon: "edit",
          to: "editpassword",
          event: "fn",
          owneronly: true,
        },
        {
          text: "currentRClick.deletepassword",
          icon: "delete_outline",
          to: "deletepassword",
          event: "fn",
          owneronly: true,
        },
        {
          text: "currentRClick.forgetpassword",
          icon: "mdi-key",
          to: "forgetpassword",
          event: "fn",
          owneronly: true,
        },
      ],
      itemCopyFile_: [
        {
          text: "copyandpaste.file_copy",
          icon: "content_copy",
          to: "copyfile_",
          event: "fn",
          owneronly: true,
        }
      ],
      itemPasteFile_: [
        {
          text: "copyandpaste.file_pasted",
          icon: "mdi-content-paste",
          to: "pastefile_",
          event: "fn",
          owneronly: true,
        }
      ],
      itemPasteFile_toFolder_: [
        {
          text: "copyandpaste.pasted_file_folder",
          icon: "mdi-content-paste",
          to: "pastefile_tofolder_",
          event: "fn",
          owneronly: true,
        }
      ],
      itemCopyFile: [
        {
          text: "copyandpaste.copy_btn",
          icon: "file_copy",
          to: "copy_file",
          event: "fn",
          owneronly: true,
        },
      ],
      itemprintPDF: [
        {
          text: "currentRClick.printPDF",
          icon: "mdi-printer",
          to: "printPDF",
          event: "fn",
        },
      ],
      itemReadFile: [
        {
          text: "currentRClick.readfile",
          icon: "mdi-file-document",
          to: "readfile",
          event: "fn",
        },
      ],
      itemsDownloadFile: [
        {
          text: "currentRClick.downloadfile",
          icon: "mdi-download",
          to: "downloadfile",
          event: "fn",
        },
        {
          text: "currentRClick.alldownload",
          icon: "mdi-download",
          to: "alldownload",
          event: "fn",
        },
        {
          text: "currentRClick.keydetail",
          icon: "mdi-information",
          to: "keydetail",
          event: "fn",
          owneronly: false,
          edit: true,
          viewonly: true,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: true,
          per02: true,
          per03: true,
          per04: true,
          per05: true,
        },
        {
          text: "currentRClick.setrequestdownload",
          icon: "mdi-download-lock",
          to: "setrequestdownload",
          event: "fn",
        },
        {
          text: "currentRClick.requestdownload",
          icon: "mdi-download-lock",
          to: "requestdownload",
          event: "fn",
        },
      ],
      itemRelationfile: [
        {
          text: "currentRClick.relationfile",
          icon: "device_hub",
          to: "relationfile",
          event: "fn",
          relation: "",
          viewonly: true,
        },
        // {
        //   text: "currentRClick.createrelationfile",
        //   icon: "mdi-plus",
        //   to: "createrelationfile",
        //   event: "fn",
        //   relation: true,
        //   viewonly: false,
        // },
        // {
        //   text: "currentRClick.deleterelationfile",
        //   icon: "mdi-trash-can",
        //   to: "deleterelationfile",
        //   event: "fn",
        //   relation: false,
        //   viewonly: false,
        // },
      ],
      itemPermission: [
        {
          text: "currentRClick.managefile",
          icon: "mdi-cogs",
          to: "managefile",
          event: "fn",
        },
      ],
      itemOpenfolder: [
        {
          text: "currentRClick.open",
          icon: "mdi-folder-open",
          to: "openfolder",
          event: "fn",
        },
        {
          text: "currentRClick.opennewtab",
          icon: "mdi-folder-open",
          to: "openfoldernewtab",
          event: "fn",
        },
      ],
      itemShare: [
        {
          text: "currentRClick.sharelinkfile",
          icon: "link",
          to: "sharefilelink",
          event: "fn",
          owneronly: true,
          edit: true,
          viewonly: false,
          download: true,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: true,
          per03: true,
          per04: true,
          per05: true,
        },
        {
          text: "currentRClick.sharelinkupload",
          icon: "mdi-link-variant-plus",
          to: "sharelinkupload",
          event: "fn",
          owneronly: true,
          edit: true,
          viewonly: false,
          download: true,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: true,
          per03: true,
          per04: true,
          per05: true,
        },
        // {
        //   text: "currentRClick.sharefile",
        //   icon: "group_add",
        //   to: "sharefilepeople",
        //   event: "fn",
        //   owneronly: true,
        //   edit: true,
        //   viewonly: false,
        //   download: true,
        //   business: true,
        //   nodirectory: true,
        //   admin: true,
        //   notmebutedit: true,

        //   per01: false,
        //   per02: true,
        //   per03: true,
        //   per04: true,
        //   per05: true,
        // },
      ],
      itemSend: [
        // {
        //   text: "currentRClick.sendfiletomail",
        //   icon: "email",
        //   to: "sendmail",
        //   event: "fn",
        //   owneronly: true,
        //   edit: true,
        //   viewonly: false,
        //   download: false,
        //   business: true,
        //   nodirectory: true,
        //   admin: true,
        //   notmebutedit: true,

        //   per01: false,
        //   per02: false,
        //   per03: false,
        //   per04: true,
        //   per05: true,
        // },
        {
          text: "currentRClick.sendfiletoonechat",
          icon: "mdi-chat",
          to: "sendonechat",
          event: "fn",
          owneronly: true,
          edit: true,
          viewonly: false,
          download: false,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: false,
          per03: false,
          per04: true,
          per05: true,
        },
      ],
      itemscopy: [
        {
          text: "คัดลอก",
          icon: "file_copy",
          to: "copyfile",
          event: "fn",
        },
        {
          text: "วาง",
          icon: "mdi-file-replace",
          to: "paste",
          event: "fn",
        },
      ],
      itemEdit: [
        {
          text: "currentRClick.rename",
          icon: "create",
          to: "editname",
          event: "fn",
          owneronly: true,
          edit: false,
          viewonly: false,
          download: false,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: false,
          per03: false,
          per04: true,
          per05: true,

          adminY: true,
        },
        {
          text: "currentRClick.editfile",
          icon: "edit",
          to: "editfile",
          event: "fn",
          owneronly: true,
          edit: false,
          viewonly: false,
          download: false,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: false,
          per03: false,
          per04: true,
          per05: true,
          adminY: true,
        },
        {
          text: "currentRClick.unlockfile",
          icon: "lock_open",
          to: "unlock",
          event: "fn",
          owneronly: true,
          edit: false,
          viewonly: false,
          download: false,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: false,
          per03: false,
          per04: true,
          per05: true,
          adminY: true,
        },
      ],
      items: [
        // {
        //   text: "currentRClick.movetotrash",
        //   icon: "delete",
        //   to: "trash",
        //   event: "fn",
        //   owneronly: true,
        //   edit:false
        // },
        {
          text: "currentRClick.movefile",
          icon: "mdi-folder-swap",
          to: "movefile",
          event: "fn",
          owneronly: true,
          edit: true,
          viewonly: false,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: false,
          per02: false,
          per03: false,
          per04: true,
          per05: true,
        },
        {
          text: "currentRClick.addtostared",
          icon: "star",
          to: "starfile",
          event: "fn",
          owneronly: false,
          edit: true,
          viewonly: true,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: true,
          per02: true,
          per03: true,
          per04: true,
          per05: true,
        },
        {
          text: "currentRClick.showinfolder",
          icon: "mdi-folder-open-outline",
          to: "showinfolder",
          event: "fn",
          owneronly: false,
          edit: true,
          viewonly: true,
          business: true,
          nodirectory: true,
          admin: true,
          notmebutedit: true,

          per01: true,
          per02: true,
          per03: true,
          per04: true,
          per05: true,
        },
        // {
        //   text: "currentRClick.keydetail",
        //   icon: "mdi-information",
        //   to: "keydetail",
        //   event: "fn",
        //   owneronly: false,
        //   edit: true,
        //   viewonly: true,
        //   business: true,
        //   nodirectory: true,
        //   admin: true,
        //   notmebutedit: true,
        // },
      ],
      itemssetprofile: [
        {
          text: "currentRClick.setprofile",
          icon: "mdi-image-multiple",
          to: "setprofile",
          event: "fn",
        },
      ],
      confirmdelete: false,
      checkAdminFolder: "",
      opensharefile: false,
      opensharelink: false,
      openpreviewfile: false,
      openchangename: false,
      opensendmail: false,
      opendlmovefile: false,
      opendialogonechat: false,
      deniedstarred: false,
      openkeydetail: false,
      opennewpreviewfile: false,
      snackbardowload: false,
      openrelationfile: false,
      opencreaterelationfile: false,
      opensetpermissionfile: false,
      opencreatepassword: false,
      openeditpassword: false,
      opendeletepassword: false,
      openforgetpassword: false,
      // showMenu: false,
      x: 0,
      y: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level", "feature_onlyoffice","feature_setprofile"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
    ]),
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          // this.confirm = this.confirmdelete
          //เช็ค คลิกขวา copy file
          let get_sessionfilecopy = [];
          get_sessionfilecopy = sessionStorage.getItem("filecopy");
          console.log("get_sessionfilecopy", get_sessionfilecopy);
          if (get_sessionfilecopy === "" || get_sessionfilecopy === null || get_sessionfilecopy === undefined) {
            this.checkbtncopy = false;
          } else {
            this.checkbtncopy = true;
          }
          console.log("checkbtncopy", this.checkbtncopy);

          this.showmenus();
          this.$emit("closeDrag");
          console.log("file", this.file);
          console.log(this.checksetpermission);
          // แก้ปัญหา คลิ๊กขวาที่โฟลเดอร์บริษัทแล้วมาคลิ๊กขวาที่โฟลเดอร์ปกติไม่ขึ้น
          if (this.file.file_status === "M") {
            console.log("file_status = M");
          } else {
            console.log("else shower");
            this.checkAdminFolder = this.file["file_permission"].admin_data;
          }
          console.log("checkAdminFolder", this.checkAdminFolder);
          console.log(this.$route.name);
          if (this.$route.name === "directory") {
            this.checkPermission = true;
          }
          // Read navigator clipboard (ปิดก่อนชั่วคราว ค่อยมาแก้ต่อ)
          // this.fn_readNavigatorClipboard(this.file.file_type, this.file)
          // if(this.confirm === false){
          //   this.confirmdelete = false
          // }
        }
        console.log("testtttt", this.show);
        return this.show;
      },
      set(value) {
        console.log("set val", value);
        if (!value) {
          console.log("not val", !value);
          this.$emit("closecurrentonly");
          this.$emit("closeDrag");
          this.$emit("openDrag");
        } else {
          console.log("val", value);
        }
      },
    },
    itemRelationfileFilter() {
      if (this.file["file_permission_2"] === "04" || this.file["file_permission_2"] === "05" || this.file["file_permission_2"] === "06") {
        if (this.file["relation_id"] === "") {
          return this.itemRelationfile.filter((menu) => menu.relation === true || menu.relation === "");
        } else {
          return this.itemRelationfile.filter((menu) => menu.relation === false || menu.relation === "");
        }
      } else {
        return this.itemRelationfile.filter((menu) => menu.relation === "");
      }
    },

    // itemsSetpermission(){
    //   console.log("itemsSetpermission");
    //   if(this.role_level === "True" || this.file["file_permission"].admin_data === 'True'){
    //       return this.itemsPermission.filter(menu => menu.admin === true);
    //   }else{
    //      return this.itemsPermission.filter(menu => menu.admin === false);
    //   }
    // },

    // แยกสิทธ์ในการใช้งาน โค๊ดจาก itemFilter
    // สำหรับ Menu
    itemShareFilter() {
      if (this.dataAccountActive.type === "Business") {
        if (this.file.editing_status === "N") {
          if (this.checksetpermission === true) {
            if ("file_owner_eng" in this.file) {
              if (this.file["file_permission_2"] === "06") {
                return this.itemShare;
              } else {
                //file
                if (this.file["file_type"] !== "folder") {
                  if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                    return this.itemShare.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                    return this.itemShare.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                    return this.itemShare.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                    return this.itemShare.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                    return this.itemShare.filter((menu) => menu.per05 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                    return this.itemShare.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                    return this.itemShare.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                    return this.itemShare.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                    return this.itemShare.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                    return this.itemShare.filter((menu) => menu.per05 === true);
                  }
                } else {
                  //folder
                  if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                    return this.itemShare.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                    return this.itemShare.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                    return this.itemShare.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                    return this.itemShare.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                    return this.itemShare.filter((menu) => menu.per05 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                    return this.itemShare.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                    return this.itemShare.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                    return this.itemShare.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                    return this.itemShare.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                    return this.itemShare.filter((menu) => menu.per05 === true);
                  }
                }
              }

              //เก่า
              // if (this.file["file_type"] !== "folder") {
              //   if (this.file["file_owner_eng"] === "Me") {
              //     return this.itemShare;
              //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
              //     return this.itemShare.filter((menu) => menu.viewonly === true);
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return this.itemShare;
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.edit === true);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.owneronly === false);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.notmebutedit === true);
              //   } else if (this.file["file_owner_eng"] !== "Me") {
              //     return this.itemShare.filter((menu) => menu.owneronly === false);
              //   }
              // } else {
              //   if (this.file["file_owner_eng"] === "Me") {
              //     return this.itemShare;
              //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
              //     return this.itemShare.filter((menu) => menu.viewonly === true);
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return this.itemShare;
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.edit === true);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.owneronly === false);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return this.itemShare.filter((menu) => menu.notmebutedit === true);
              //   } else if (this.file["file_owner_eng"] !== "Me") {
              //     return this.itemShare.filter((menu) => menu.owneronly === false);
              //   } else {
              //     return this.itemShare.filter((menu) => menu.owneronly === "" || menu.owneronly === false);
              //   }
              // }
            } else {
              return this.itemShare;
            }
          } else {
            return this.itemShare.filter((menu) => menu.nodirectory === "" || menu.nodirectory === true);
          }
        } else {
        }
      } else {
        // return this.itemShare.filter((menu) => menu.business === "" || menu.business === true);
        return this.itemShare;
      }
    },

    itemsFilter() {
      if (this.dataAccountActive.type === "Business") {
        console.log("file rightclick", this.file);
        // console.log("this.role_level",this.role_level);
        if (this.file.editing_status === "N") {
          if(this.file.status_expired != 0){        
            if (this.checksetpermission === true) {
              if ("file_owner_eng" in this.file) {
                if (this.file["file_permission_2"] === "06") {
                  //  return this.items.filter(menu => menu.admin === true);
                  return this.items;
                } else {
                  //file
                  // if (this.file["file_type"] !== "folder") {
                  //   if(this.file["file_owner_eng"] === "Me"){
                  //     return this.items.filter((menu) => menu.per05 === true);
                  //   // if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                  //   //   return this.items.filter((menu) => menu.per01 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                  //   //   return this.items.filter((menu) => menu.per02 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                  //   //   return this.items.filter((menu) => menu.per03 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                  //   //   return this.items.filter((menu) => menu.per04 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                  //   //   return this.items.filter((menu) => menu.per05 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                  //     return this.items.filter((menu) => menu.per01 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                  //     return this.items.filter((menu) => menu.per02 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                  //     return this.items.filter((menu) => menu.per03 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                  //     return this.items.filter((menu) => menu.per04 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                  //     return this.items.filter((menu) => menu.per05 === true);
                  //   }
                  // } else {
                  //   if(this.file["file_owner_eng"] === "Me"){
                  //     return this.items.filter((menu) => menu.per05 === true);
                  //   // if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                  //   //   return this.items.filter((menu) => menu.per01 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                  //   //   return this.items.filter((menu) => menu.per02 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                  //   //   return this.items.filter((menu) => menu.per03 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                  //   //   return this.items.filter((menu) => menu.per04 === true);
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                  //   //   return this.items.filter((menu) => menu.per05 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                  //     return this.items.filter((menu) => menu.per01 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                  //     return this.items.filter((menu) => menu.per02 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                  //     return this.items.filter((menu) => menu.per03 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                  //     return this.items.filter((menu) => menu.per04 === true);
                  //   } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                  //     return this.items.filter((menu) => menu.per05 === true);
                  //   }
                  //   //folder
                  //   // if (this.file["file_owner_eng"] === "Me") {
                  //   //   return this.items;
                  //   // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
                  //   //   return this.items.filter((menu) => menu.viewonly === true);
                  //   // } else if (
                  //   //   this.file["file_owner_eng"] === "Me" &&
                  //   //   this.file["file_permission"].view_only === "True" &&
                  //   //   this.file["file_permission"].edit === "True"
                  //   // ) {
                  //   //   return this.items;
                  //   // } else if (
                  //   //   this.file["file_owner_eng"] === "Me" &&
                  //   //   this.file["file_permission"].view_only === "True" &&
                  //   //   this.file["file_permission"].edit === "False"
                  //   // ) {
                  //   //   return this.items.filter((menu) => menu.edit === true);
                  //   // } else if (
                  //   //   this.file["file_owner_eng"] !== "Me" &&
                  //   //   this.file["file_permission"].view_only === "True" &&
                  //   //   this.file["file_permission"].edit === "False"
                  //   // ) {
                  //   //   return this.items.filter((menu) => menu.owneronly === false);
                  //   // } else if (
                  //   //   this.file["file_owner_eng"] !== "Me" &&
                  //   //   this.file["file_permission"].view_only === "True" &&
                  //   //   this.file["file_permission"].edit === "True"
                  //   // ) {
                  //   //   return this.items.filter((menu) => menu.notmebutedit === true);
                  //   // } else if (this.file["file_owner_eng"] !== "Me") {
                  //   //   return this.items.filter((menu) => menu.owneronly === false);
                  //   // }
                  //   // // else if(this.file["file_permission"].view_only === 'False'){
                  //   // //    return this.items;
                  //   // // }
                  //   // else {
                  //   //   return this.items.filter((menu) => menu.owneronly === "" || menu.owneronly === false);
                  //   // }

                  //   //------------------------------------------------------------------------------------
                  //   // if(this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === '01'){
                  //   //   console.log("เข้า01");
                  //   //   return this.items.filter((menu) => menu.per01 === true);

                  //   // }else if(this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === '02'){
                  //   //   console.log("เข้า02");
                  //   //   return this.items.filter((menu) => menu.per02 === true);

                  //   // }else if(this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === '03'){
                  //   //   console.log("เข้า03");
                  //   //   return this.items.filter((menu) => menu.per03 === true);

                  //   // }else if(this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === '04'){
                  //   //   console.log("เข้า04");
                  //   //   return this.items.filter((menu) => menu.per04 === true);

                  //   // }else if(this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === '05'){
                  //   //   console.log("เข้า05");
                  //   //    return this.items.filter((menu) => menu.per05 === true);

                  //   // }else if(this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === '01'){
                  //   //   return this.items.filter((menu) => menu.per01 === true);

                  //   // }else if(this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === '02'){
                  //   //   return this.items.filter((menu) => menu.per02 === true);

                  //   // }else if(this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === '03'){
                  //   //   return this.items.filter((menu) => menu.per03 === true);

                  //   // }else if(this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === '04'){
                  //   //   return this.items.filter((menu) => menu.per04 === true);

                  //   // }else if(this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === '05'){
                  //   //   return this.items.filter((menu) => menu.per05 === true);

                  //   // }
                  // }
                  if (this.file["file_permission_2"] === "01") {
                    return this.items.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_permission_2"] === "02") {
                    return this.items.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_permission_2"] === "03") {
                    return this.items.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_permission_2"] === "04") {
                    return this.items.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_permission_2"] === "05") {
                    return this.items.filter((menu) => menu.per05 === true);
                  }
                }

                //เก่า
                // // if(this.role_level === "True" || this.file["file_permission"].admin_data === 'True'){
                // //      return this.items.filter(menu => menu.admin === true);
                // // }else{
                // if (this.file["file_type"] !== "folder") {
                //   if (this.file["file_owner_eng"] === "Me") {
                //     return this.items;
                //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
                //     return this.items.filter((menu) => menu.viewonly === true);
                //   } else if (
                //     this.file["file_owner_eng"] === "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "True"
                //   ) {
                //     return this.items;
                //   } else if (
                //     this.file["file_owner_eng"] === "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "False"
                //   ) {
                //     return this.items.filter((menu) => menu.edit === true);
                //   } else if (
                //     this.file["file_owner_eng"] !== "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "False"
                //   ) {
                //     return this.items.filter((menu) => menu.owneronly === false);
                //   } else if (
                //     this.file["file_owner_eng"] !== "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "True"
                //   ) {
                //     return this.items.filter((menu) => menu.notmebutedit === true);
                //   } else if (this.file["file_owner_eng"] !== "Me") {
                //     return this.items.filter((menu) => menu.owneronly === false);
                //   }
                //   // else{
                //   //   return this.items;
                //   // }
                // } else {
                //   if (this.file["file_owner_eng"] === "Me") {
                //     return this.items;
                //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
                //     return this.items.filter((menu) => menu.viewonly === true);
                //   } else if (
                //     this.file["file_owner_eng"] === "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "True"
                //   ) {
                //     return this.items;
                //   } else if (
                //     this.file["file_owner_eng"] === "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "False"
                //   ) {
                //     return this.items.filter((menu) => menu.edit === true);
                //   } else if (
                //     this.file["file_owner_eng"] !== "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "False"
                //   ) {
                //     return this.items.filter((menu) => menu.owneronly === false);
                //   } else if (
                //     this.file["file_owner_eng"] !== "Me" &&
                //     this.file["file_permission"].view_only === "True" &&
                //     this.file["file_permission"].edit === "True"
                //   ) {
                //     return this.items.filter((menu) => menu.notmebutedit === true);
                //   } else if (this.file["file_owner_eng"] !== "Me") {
                //     return this.items.filter((menu) => menu.owneronly === false);
                //   }
                //   // else if(this.file["file_permission"].view_only === 'False'){
                //   //    return this.items;
                //   // }
                //   else {
                //     return this.items.filter((menu) => menu.owneronly === "" || menu.owneronly === false);
                //   }
                // }
                // //}
              } else {
                console.log("เข้า1");
                return this.items;
              }
            } else {
              console.log("เข้า2");
              // return this.items.filter((menu) => menu.nodirectory === "" || menu.nodirectory === true);
              if (this.file["file_permission_2"] === "01") {
                return this.items.filter((menu) => menu.per01 === true);
              } else if (this.file["file_permission_2"] === "02") {
                return this.items.filter((menu) => menu.per02 === true);
              } else if (this.file["file_permission_2"] === "03") {
                return this.items.filter((menu) => menu.per03 === true);
              } else if (this.file["file_permission_2"] === "04") {
                return this.items.filter((menu) => menu.per04 === true);
              } else if (this.file["file_permission_2"] === "05") {
                return this.items.filter((menu) => menu.per05 === true);
              }
            }
          }else{

          }
        } else {
          console.log("ไม่มี status editting");
        }
      } else {
        console.log("เข้า3");
        return this.items;
        // return this.items.filter((menu) => menu.business === "" || menu.business === true);
      }
    },
    disableByPackage() {      
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false
    }
  },
  // watch:{
  //    confirm:function(val){

  //    }
  //   },
  methods: {
    async fn_readNavigatorClipboard (typefile, infor_file) {
      // DEFAULT OPEN
      this.isOpen_pastefile = false
      this.isOpen_pastetofolder = false
      try{
        let ReadClipBoard =  await navigator.clipboard.readText()
          // ทำการ decrypt files_information ทั้งหมด
        var JSONParse_ClipArray = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(ReadClipBoard, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }))) 
        console.log("JSONParse ", JSONParse_ClipArray)
        if(this.dataAccountActive.type === "Citizen" && (this.$route.path === "/mydrive" || this.$route.name === "directory" )){
            if(JSONParse_ClipArray.taxId === "" && JSONParse_ClipArray.user_id === this.dataUsername){
              if(typefile !== "folder"){
                console.log("สามารถวางใน folder root นี้ => วางไฟล์")
                this.isOpen_pastefile = true
              } else {
                console.log("สามารถวางใน folder root นี้ => วางไฟล์ในโฟลเดอร์นี้ได้")
                this.isOpen_pastetofolder = true
              }
              return
            } else {
              console.log("ไม่มีสามารถวางใน folder root นี้ เพราะว่า ก็อปมาจาก biz อื่นหรือ account ที่ไม่ตรงกัน")
              this.isOpen_pastefile = false
              this.isOpen_pastetofolder = false
              return
            }
        } else if (this.dataAccountActive.type === "Business" && (this.$route.path === "/mydrive" || this.$route.name === "directory" )) {
            // ต้องมีการเช็คก่อนว่ก็อปมาจากไหน citizen หรือ Biz อื่น
            // ถ้าหากก็อปมาจาก Citizen หรือ จากในBizเดียวกันและต้องเป็น user_id เดียวกัน => สามารถวางได้
            if((JSONParse_ClipArray.taxId === "" || JSONParse_ClipArray.taxId === this.dataAccountActive.taxid) && JSONParse_ClipArray.user_id === this.dataUsername){
              if(typefile !== "folder"){
                // ต้องมีการเช็คสิทธิ์ต่อว่า rootfile นั้นๆมีสิทธิ์หรือป่าว
                if(["03", "04", "05", "06"].includes(this.permission_folder)){
                  console.log("มีสิทธิ์วางใน folder root นี้ => วางไฟล์")
                  this.isOpen_pastefile = true
                } else {
                  console.log("ไม่มีสิทธิ์วางใน folder root นี้ เพราะว่า rootfolder นี้ถูกตั้งสิทธิ์น้อยกว่า 04")
                }
              } else {
                if(["03", "04", "05", "06"].includes(infor_file.file_permission_2)){
                  console.log("มีสิทธิ์วางใน folder root นี้ => วางไฟล์ และ วางไฟล์ในโฟลเดอร์นี้ได้")
                  this.isOpen_pastetofolder = true
                } else {
                  console.log("ไม่มีสิทธิ์วางใน folder root นี้ => ไม่มีสิทธิ์วางไฟล์ในโฟลเดอร์นี้")
                }
              }
              
            } else {
                console.log("ไม่มีสิทธิ์วางใน folder root นี้ เพราะว่า ก็อปมาจาก account อื่น")
                return 
            }
            
        }
        
      } catch (err) {
        this.isOpen_pastefile = false
        this.isOpen_pastetofolder = false
        console.log("ไม่มีสิทธิ์จะวางไฟล์ที่ copy มาได้เพราะว่า decrypt ผิดพลาด")
        return
      }
      
    },
    fn_sendtosettingprofile(current){
      console.log("current",current);
    },
    async fn_printPDF_single() {
      let listfile = "";

      this.$emit("loading_print");
      listfile = "&list_file=" + this.file.file_id;
      if (this.file.file_type === "pdf") {
        let auth = await gbfGenerate.generateToken();
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/merge_pdf?account_id=" +
          this.dataAccountId +
          "&user_id=" +
          this.dataUsername +
          "&Authorization=" +
          auth.code +
          listfile;
        this.axios.get(url, { responseType: 'blob' }).then((response) => {
          //console.log(response.data.status);
          this.$emit("disloading_print");
          if (response.data.type === "application/pdf") {
            this.$emit("disloading_print");
            let url = window.URL.createObjectURL(response.data);
            window.open(url).print();
          } else {
            this.$emit("disloading_print");
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถพิมพ์ไฟล์เสียได้",
            });
          }
        });
      } else if (['jpeg', 'jpg', 'png', 'gif', 'tiff'].includes(this.file.file_type.toLowerCase())) {
        let auth = await gbfGenerate.generateToken();
        let url = process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/v1/convert/file_to_pdf?id=" + this.file.file_id;
        this.axios.get(url, { responseType: 'blob', headers: { Authorization: auth.code } }).then((response) => {
          this.$emit("disloading_print");
          if (response.data.type === "application/pdf") {
            this.$emit("disloading_print");
            let url = window.URL.createObjectURL(response.data);
            window.open(url).print();
          } else {
            this.$emit("disloading_print");
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถพิมพ์ไฟล์เสียได้",
            });
          }
        });
      }
    },
    fn_shortcut() {
      this.openshortcut = true;
    },
    fn_redelete() {
      this.$emit("callupdatestatusfile", this.file, "T");
      this.confirmdelete = false;
    },
    // จัดตำแหน่ง Submenu ให้ไปอยู่ทางซ้าย
    fn_checkpositionmenu() {
      let listWidth = 250;
      if (this.x + listWidth > window.innerWidth) {
        return true;
      } else {
        return false;
      }
    },
    fn_itemfilter(_listitem) {
      // ส่วนของการเพิ่มตัวแปร
      let filterItem;
      if (_listitem == "share") {
        if (this.file.file_type !== "folder") {
          filterItem = [];
          filterItem.push(this.itemShare[0]);
          // filterItem.push(this.itemShare[2]);
        } else {
          filterItem = this.itemShare;
        }
      } else if (_listitem == "sendto") {
        filterItem = this.itemSend;
      } else if (_listitem == "edit") {
        filterItem = this.itemEdit;
      }
      // ส่วนของการ filter
      if (this.dataAccountActive.type === "Business") {
        if (this.file.editing_status === "N") {
          if (this.checksetpermission === true) {
            if ("file_owner_eng" in this.file) {
              if (this.file["file_permission_2"] === "06") {
                return filterItem;
              } else {
                if (this.file["file_type"] !== "folder") {
                  //file
                  if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                    return filterItem.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                    return filterItem.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                    return filterItem.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                    return filterItem.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                    return filterItem.filter((menu) => menu.per05 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                    return filterItem.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                    return filterItem.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                    return filterItem.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                    return filterItem.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                    return filterItem.filter((menu) => menu.per05 === true);
                  }
                } else {
                  //folder
                  if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "01") {
                    return filterItem.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "02") {
                    return filterItem.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "03") {
                    return filterItem.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "04") {
                    return filterItem.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission_2"] === "05") {
                    return filterItem.filter((menu) => menu.per05 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "01") {
                    return filterItem.filter((menu) => menu.per01 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "02") {
                    return filterItem.filter((menu) => menu.per02 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "03") {
                    return filterItem.filter((menu) => menu.per03 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "04") {
                    return filterItem.filter((menu) => menu.per04 === true);
                  } else if (this.file["file_owner_eng"] !== "Me" && this.file["file_permission_2"] === "05") {
                    return filterItem.filter((menu) => menu.per05 === true);
                  }
                }
              }

              //เก่า
              // if (this.file["file_type"] !== "folder") {
              // if (this.file["file_owner_eng"] === "Me") {
              //     return filterItem;
              //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
              //     return filterItem.filter((menu) => menu.viewonly === true);
              //     } else if(
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].download === "True"
              //     ){
              //     return filterItem.filter((menu) => menu.download === true);
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return filterItem;
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return filterItem.filter((menu) => menu.edit === true);
              //   } else if(
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].download === "True"
              //   ){
              //     return filterItem.filter((menu) => menu.download === true);
              //   }else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return filterItem.filter((menu) => menu.owneronly === false);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return filterItem.filter((menu) => menu.notmebutedit === true);
              //   } else if (this.file["file_owner_eng"] !== "Me") {
              //     return filterItem.filter((menu) => menu.owneronly === false);
              //   }

              // } else {
              //   if (this.file["file_owner_eng"] === "Me") {
              //     return filterItem;
              //   } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
              //     return filterItem.filter((menu) => menu.viewonly === true);
              //     } else if(
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].download === "True"
              //     ){
              //     return filterItem.filter((menu) => menu.download === true);
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return filterItem;
              //   } else if (
              //     this.file["file_owner_eng"] === "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return filterItem.filter((menu) => menu.edit === true);
              //   } else if(
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].download === "True"
              //   ){
              //     return filterItem.filter((menu) => menu.download === true);
              //   }else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "False"
              //   ) {
              //     return filterItem.filter((menu) => menu.owneronly === false);
              //   } else if (
              //     this.file["file_owner_eng"] !== "Me" &&
              //     this.file["file_permission"].view_only === "True" &&
              //     this.file["file_permission"].edit === "True"
              //   ) {
              //     return filterItem.filter((menu) => menu.notmebutedit === true);
              //   } else if (this.file["file_owner_eng"] !== "Me") {
              //     return filterItem.filter((menu) => menu.owneronly === false);
              //   }

              ///////////////////////////////////////////////////////////////////////////////////////////
              // if (this.file["file_owner_eng"] === "Me") {
              //   return filterItem;
              // } else if (this.file["file_owner_eng"] === "Me" && this.file["file_permission"].view_only === "True") {
              //   return filterItem.filter((menu) => menu.viewonly === true);

              // } else if(
              //   this.file["file_owner_eng"] === "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].download === "True"
              //   ){
              //   return filterItem.filter((menu) => menu.download === true);
              //   }else if (
              //   this.file["file_owner_eng"] === "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].edit === "True"
              // ) {
              //   return filterItem;
              // } else if (
              //   this.file["file_owner_eng"] === "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].edit === "False"
              // ) {
              //   return filterItem.filter((menu) => menu.edit === true);
              // } else if (
              //   this.file["file_owner_eng"] !== "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].edit === "False"
              // ) {
              //   return filterItem.filter((menu) => menu.owneronly === false);
              // } else if(
              //   this.file["file_owner_eng"] !== "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].download === "True"
              //   ){
              //   return filterItem.filter((menu) => menu.download === true);
              // }else if (
              //   this.file["file_owner_eng"] !== "Me" &&
              //   this.file["file_permission"].view_only === "True" &&
              //   this.file["file_permission"].edit === "True"
              // ) {
              //   return filterItem.filter((menu) => menu.notmebutedit === true);
              // } else if (this.file["file_owner_eng"] !== "Me") {
              //   return filterItem.filter((menu) => menu.owneronly === false);
              // } else {
              //   return filterItem.filter((menu) => menu.owneronly === "" || menu.owneronly === false);
              // }
              // }
            } else {
              return filterItem;
            }
          } else {
            return filterItem.filter((menu) => menu.nodirectory === "" || menu.nodirectory === true);
          }
        } else {
          console.log("เป็น Y");
          if (this.file["file_permission_2"]=== "06") {
            return filterItem.filter((menu) => menu.adminY === true);
          }
        }
      } else {
        return filterItem;
        // return filterItem.filter((menu) => menu.business === "" || menu.business === true);
      }
    },
    reloadsetexpired() {
      this.$emit("reload");
    },
    gotodirectory(folderid, type, departmentid, file) {
      console.log("file++++++++++++++", file);
      if (file.file_type === "folder") {
        if (file.account_sender === this.dataAccountId) {
          this.$store.dispatch("department_access_id", file.file_department);
          this.$router.replace({ path: "/directory/" + file.file_id });
          this.$emit("reload");
          this.$emit("closecurrentonly")
        } else {
          // this.$store.dispatch("department_access_id", file.file_department);
          this.$router.replace({ path: "/directorysharefile/" + file.file_id });
          this.$emit("reload");
        }
        this.$emit("updateparentfolder");
      } else {
        this.openpreviewfile = true;
        // this.opendialog_checklog = true
      }
      // console.log("file",file);
      // if(file.status_lock_folder === 'Y'&& type === 'folder' || file.status_lock_file === 'Y' && type !== 'folder'){
      //   this.opendialog_checklog = true
      //   this.newdata = file
      //   console.log("newdata",this.newdata);
      // }else{
      //   this.checkgotodirectory(file)
      // }
    },
    gotodirectory_newtab(folderid, type, departmentid, file) {
      console.log("file++++++++++++++", file);
      if (file.file_type === "folder") {
        if (file.account_sender === this.dataAccountId) {
          this.$store.dispatch("department_access_id", file.file_department);
          let route = this.$router.resolve({ path: "/directory/" + file.file_id });
          window.open(route.href);
          this.$emit("reload");
        } else {
          let route = this.$router.resolve({ path: "/directorysharefile/" + file.file_id });
          window.open(route.href);
          this.$emit("reload");
        }
      } else {
        // this.openpreviewfile = true;
      }
    },
    checklockfile(file) {
      this.$emit("callfile", file.file_id, file.file_type, file.file_department, file);
    },
    async setmultiupdate(filemultipledownload, status) {
      // console.log('aaaa',this.filemultipledownload,status);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let apifile;
        let apifolder;
        let payloadfile;
        let payloadfolder;
        let msgres;
        let filedata = [];
        let folderdata = [];
        let i;
        console.log(this.filemultipledownload, status);
        for (i = 0; i < this.filemultipledownload.length; i++) {
          if (this.filemultipledownload[i].file_type === "folder") {
            folderdata.push(this.filemultipledownload[i]["file_id"]);
            payloadfolder = {
              list_folder: folderdata,
              account_id: this.dataAccountId,
              status_folder: status,
            };
            apifolder = "/api/multiple_update_status_folders";
          } else {
            filedata.push(this.filemultipledownload[i]["file_id"]);
            payloadfile = {
              list_file: filedata,
              account_id: this.dataAccountId,
              status_file: status,
            };
            apifile = "/api/multiple_update_status_files";
          }
        }
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifile, payloadfile, { headers: { Authorization: auth.code } })
          .then((response) => {
            if (response.data.status === "OK") {
              if (status === "S") {
                msgres = "ติดดาวสำเร็จ";
              } else if (status === "T") {
                msgres = "นำออกสำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาวสำเร็จ";
              }
              Toast.fire({
                icon: "success",
                title: msgres,
              });
              this.clearmultipledownload();
              this.$emit("loadfolder");
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
        this.axios
          .post(process.env.VUE_APP_SERVICE_UPDATE_MULTIPLES + apifolder, payloadfolder, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            if (response.data.status === "OK") {
              if (status === "S") {
                msgres = "ติดดาวสำเร็จ";
              } else if (status === "T") {
                msgres = "นำออกสำเร็จ";
              } else {
                msgres = "ยกเลิกติดดาวสำเร็จ";
              }
              Toast.fire({
                icon: "success",
                title: msgres,
              });
              this.clearmultipledownload();
              this.statusmutipledownload = false;
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },
    showmenus() {
      console.log("testqqqq");
      this.showMenu = false;
      this.x = this.AreaX;
      this.y = this.AreaY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    checkBusinessDrive(parameter) {
      if (parameter === "M") {
        return true;
      } else if (this.dataAccountActive.type === "Business" && this.file["file_type"] === "folder") {
        if ("file_owner_eng" in this.file) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    // ไม่ใช่เปลี่ยนเป็น ใช้ใน dialog-checkmultidownload
    async multipledownload() {
      console.log("CRC", this.filemultipledownload);
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          icon: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          // push type
          if (this.filemultipledownload[i]["file_type"] === "folder") {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: this.filemultipledownload[i]["file_type"],
            });
          } else {
            filedata.push({
              id: this.filemultipledownload[i]["file_id"],
              type: "file",
            });
          }
        }
        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        console.log("payloads", payload);
        // this.snackbardowloadfile = true;
        console.log("ถึงง");
        this.opensnackbarmultidownload = true;

        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/download_multiples_all", payload, {
            headers: { Authorization: auth.code },
            onDownloadProgress: (progressEvent) => {
              // console.log(progressEvent);
              let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              // console.log(progresspercent);
              if (progresspercent >= 95) {
                this.percentCompleted = 100;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
              console.log("this.percent", this.percent);
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            console.log(response);
            if (response.statusText === "OK") {
              this.percentCompleted = 100;
            }
            const blob = new Blob([response.data]);
            const content = response.headers["content-type"];
            saveAs(blob, this.datetime + ".zip");
            this.clearmultipledownload();
          })
          .catch((error) => {
            this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              // title: this.$t("myinboxPage.msg.apierror")
              title: response.data.errorMessage,
            });
            console.log(error);
          });
      }
    },
    //! คลิกขวา
    clicker(parameter, type, currentfile, filemultipledownload) {
      let currentfile_type_file = currentfile.file_name.split(".");
      console.log("คลิกขวา", parameter, type, currentfile_type_file);
      if (type === "emit") {
        this.$emit(parameter);
      } else {
        // เชค parameter แล้วเปิด dialog ตาม parameter ที่ส่งมา
        if (currentfile.status_lock_folder === "Y" || currentfile.status_lock_data === "Y") {
          // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
          this.$emit("status_createpass", ['previewnewtab', 'openfoldernewtab'].includes(parameter) ? false : true)
          if (parameter === "forgetpassword") {
            this.openforgetpassword = true;
            this.$emit("closedragselect");
          } else if (parameter === "deletepassword") {
            this.opendeletepassword = true;
            this.$emit("closedragselect");
          } else if (parameter === "editpassword") {
            this.openeditpassword = true;
            this.$emit("closedragselect");
          } else if (parameter === "preview") {
            // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
            // if(checkinet_jv === -1){
              // let checkinet_jv = VueCookies.get("feature_onlyoffice")
              // if(checkinet_jv === 'false'){
              let checkinet_jv = this.feature_onlyoffice;
              if(checkinet_jv === false){
              this.param_preview = "preview";
              this.openpreviewfile = true;
              this.$emit("closedragselect");
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) || 
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            if(currentfile.editing_status === 'S'){
              this.openpreviewfile = false;
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }else if(currentfile.editing_status === 'Y'){
              if(currentfile.last_update_name.name_th === 'ฉัน'){
                this.openpreviewfile = true;
                this.$emit("closedragselect");
              }else{
                this.openpreviewfile = false;
                Toast.fire({
                  icon: "warning",
                  title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
                });
              }
            }else{
              this.openpreviewfile = true;
              this.$emit("closedragselect");
            }
          } else if (parameter === "previewnewtab") {
            // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
            // if(checkinet_jv === -1){
              // let checkinet_jv = VueCookies.get("feature_onlyoffice")
              // if(checkinet_jv === 'false'){
              let checkinet_jv = this.feature_onlyoffice;
              if(checkinet_jv === false){
              this.param_preview = "preview";
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) || 
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            this.fn_previewnewtab(currentfile);
          } else if (parameter === "openfolder") {
            this.gotodirectory(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
          } else if (parameter === "openfoldernewtab") {
            this.gotodirectory_newtab(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
          }else if (parameter === "keydetail"){
            //this.$emit("opendetailfile")
             this.openkeydetail = true;
             this.$emit("closedragselect");
          }else {
            console.log("currentfile", currentfile);
            // if (currentfile.file_type !== "folder") {
            this.opendialog_checklog = true;
            this.sendparameter = parameter;
            this.sendcurrentfile = currentfile;
            this.$emit("closedragselect");
            // } else {
            //   this.opendialog_checklog_password = true;
            //   // this.opendialog_checklog = true;
            //   this.sendparameter = parameter;
            //   this.sendcurrentfile = currentfile;
            // }
          }
        } else {
          if (parameter === "starfile") {
            if (currentfile.file_status === "S") {
              this.$emit("callupdatestatusfile", currentfile, "N");
            } else {
              this.$emit("callupdatestatusfile", currentfile, "S");
            }
          } else if (parameter === "trash") {
            this.confirmdelete = true;
            this.$emit("closedragselect");
            // this.$emit("callupdatestatusfile", currentfile, "T");
            console.log("------------trash--------------");
          } else if (parameter === "alltrash") {
            this.$emit("newmultidelete");
          } else if (parameter === "downloadfile") {
            // this.download_new(currentfile);
            this.downloadfile(currentfile);
            // this.opensnackbar = true;
          } else if (parameter === "alldownload") {
            this.multipledownload();
          } else if (parameter === "editname") {
            this.openchangename = true;
            this.$emit("closedragselect");
          } else if (parameter === "editfile") {
            this.fn_editfile(currentfile);
          } else if (parameter === "unlock") {
            this.fn_unlock(currentfile);
          } else if (parameter === "sendmail") {
            this.opensendmail = true;
            this.$emit("closedragselect");
          } else if (parameter === "movefile") {
            this.opendlmovefile = true;
            this.$emit("closedragselect");
          } else if (parameter === "sendonechat") {
            this.opendialogonechat = true;
            this.$emit("closedragselect");
          } else if (parameter === "preview") {
            console.log("currentfile", currentfile);
            // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
            // if(checkinet_jv === -1){
              // let checkinet_jv = VueCookies.get("feature_onlyoffice")
              // if(checkinet_jv === 'false'){
              let checkinet_jv = this.feature_onlyoffice;
              this.$emit("closedragselect");
              if(checkinet_jv === false){
              this.param_preview = "preview";
              this.openpreviewfile = true;
              this.$emit("closedragselect");
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) ||
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            if(currentfile.editing_status === 'S'){
              this.openpreviewfile = false;
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }else if(currentfile.editing_status === 'Y'){
              this.openpreviewfile = true;
              // if(currentfile.last_update_name.name_th === 'ฉัน'){
              //   this.openpreviewfile = true;
              //   this.$emit("closedragselect");
              // }else{
              //   this.openpreviewfile = false;
              //   Toast.fire({
              //     icon: "warning",
              //     title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              //   });
              // }
            }else{
              this.openpreviewfile = true;
              this.$emit("closedragselect");
            }
          } else if (parameter === "previewnewtab") {
            // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
            // if(checkinet_jv === -1){
            // let checkinet_jv = VueCookies.get("feature_onlyoffice")
            // if(checkinet_jv === 'false'){
            let checkinet_jv = this.feature_onlyoffice;
              if(checkinet_jv === false){
              this.param_preview = "preview";
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) || 
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            this.fn_previewnewtab(currentfile);
          } else if (parameter === "sharefilelink") {
            this.opensharelink = true;
            this.$emit("closedragselect");
          } else if (parameter === "sharelinkupload") {
            this.opensharelinkuploads = true;
            this.$emit("closedragselect");
          } else if (parameter === "sharefilepeople") {
            this.opensharefile = true;
            this.$emit("closedragselect");
          } else if (parameter === "keydetail") {
            // this.$emit("opendetailfile")
            this.openkeydetail = true;
            this.$emit("closedragselect");
          } else if (parameter === "newpreview") {
            this.opennewpreviewfile = true;
            this.$emit("closedragselect");
          } else if (parameter === "managefile") {
            console.log(this.$route.params.id);
            this.opensetpermissionfile = true;
            this.$emit("closedragselect");
          } else if (parameter === "relationfile") {
            this.openrelationfile = true;
            this.$emit("closedragselect");
          } else if (parameter === "createrelationfile") {
            this.opencreaterelationfile = true;
            this.$emit("closedragselect");
          } else if (parameter === "deleterelationfile") {
            this.$emit("deleterelation", currentfile);
          } else if (parameter === "createpassword") {
            this.opencreatepassword = true;
            this.$emit("closedragselect");
            // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
            this.$emit("status_createpass", true)
          } else if (parameter === "editpassword") {
            this.openeditpassword = true;
            this.$emit("closedragselect");
            // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
            this.$emit("status_createpass", true)
          } else if (parameter === "deletepassword") {
            this.opendeletepassword = true;
            this.$emit("closedragselect");
            // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
            this.$emit("status_createpass", true)
          } else if (parameter === "showinfolder") {
            this.$emit("callparantfolder", currentfile);
          } else if (parameter === "tagversion") {
            this.opendialog_tagversion = true;
            this.$emit("closedragselect");
            console.log("tag file", currentfile);
            console.log("file", this.file);
          } else if (parameter === "openfolder") {
            console.log("openfile clicker");
            console.log("file", this.file);
            console.log("curentfile", currentfile);
            if (this.file.file_type === "folder") {
              // gotodirectory function
              this.gotodirectory(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
            }
          } else if (parameter === "openfoldernewtab") {
            if (this.file.file_type === "folder") {
              this.gotodirectory_newtab(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
            }
          } else if (parameter === "setexpired") {
            this.opensetexpired = true;
            this.$emit("closedragselect");
          } else if (parameter === "editexpired") {
            this.opensetexpired = true;
            this.$emit("closedragselect");
          } else if (parameter === "cancelexpired") {
            this.opencancelexpired = true;
            this.$emit("closedragselect");
          } else if (parameter === "copyfile") {
            this.fn_copyfile(currentfile);
          } else if (parameter === "paste") {
            this.fn_pastefile(currentfile);
          } else if (parameter === "readfile") {
            this.fn_createfileOCR(currentfile);
          } else if (parameter === "editfileonline"){
            this.param_preview = "editfileonline"
            if(currentfile.editing_status === 'S'){
              this.openpreviewfile = false;
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }else if(currentfile.editing_status === 'Y'){
              if(currentfile.last_update_name.name_th === 'ฉัน'){
                this.openpreviewfile = true;
                this.$emit("closedragselect");
              }else{
                this.openpreviewfile = false;
                Toast.fire({
                  icon: "warning",
                  title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
                });
              }
            }else{
              this.openpreviewfile = true;
              this.$emit("closedragselect");
            }

          } else if (parameter === "setprofile"){
            this.fn_sendtosettingprofile(currentfile);
          } else if (parameter === "copy_file") { //NEW GENERATION
            //this.fn_copyfile(currentfile)
            this.$emit("closecurrentonly")
            console.log("Triggled Copyy Pastes")
            this.fn_newcopyfile(currentfile)
          } else if (parameter === "copyfile_") { // NEW GENERATION
            console.log("ROUTEE ", this.$route)
            this.$emit("closecurrentonly")
            this.fn_copyfile_(currentfile)
          } else if (parameter === "pastefile_") {
            this.$emit("closecurrentonly")
            this.fn_pastefiles(currentfile, "toroot_")
          } else if (parameter === "pastefile_tofolder_") {
            //console.log("Paste file to folder here ")
            this.$emit("closecurrentonly")
            this.fn_pastefiles(currentfile, "tofolder_")
          }else if (parameter === "setrequestdownload"){ 
            console.log("เข้า");
            this.opendialogsetrequest = true;
          }else if (parameter === "requestdownload"){ 
            this.opendialogrequesttodownload = true;
          }

        }
        this.$emit('closecurrentonly')
      }
    },
    // ดับเบิ้ลคลิก
    clickclick(parameter, currentfile) {
      this.opendialog_checklog_password = false;
      this.opendialog_checklog = false;
      this.$emit("status_createpass", false)
      if (parameter === "starfile") {
        if (currentfile.file_status === "S") {
          this.$emit("callupdatestatusfile", currentfile, "N");
        } else {
          this.$emit("callupdatestatusfile", currentfile, "S");
        }
      } else if (parameter === "trash") {
        this.$emit("callupdatestatusfile", currentfile, "T");
        this.$emit("callstorage");
      } else if (parameter === "alltrash") {
        this.$emit("newmultidelete");
        this.$emit("callstorage");
      } else if (parameter === "downloadfile") {
        this.downloadfile(currentfile);
        // this.opensnackbar = true;
      } else if (parameter === "alldownload") {
        this.multipledownload();
      } else if (parameter === "editname") {
        this.openchangename = true;
      } else if (parameter === "editfile") {
        this.fn_editfile(currentfile);
      } else if (parameter === "unlock") {
        this.fn_unlock(currentfile);
      } else if (parameter === "sendmail") {
        this.opensendmail = true;
      } else if (parameter === "movefile") {
        this.opendlmovefile = true;
      } else if (parameter === "sendonechat") {
        this.opendialogonechat = true;
      } else if (parameter === "preview") {
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
        //     if(checkinet_jv === -1){
          // let checkinet_jv = VueCookies.get("feature_onlyoffice")
          // if(checkinet_jv === 'false'){
          let checkinet_jv = this.feature_onlyoffice;
          
            if(checkinet_jv === false){
              this.param_preview = "preview";
              this.openpreviewfile = true;
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) || 
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            if(currentfile.editing_status === 'S'){
              this.openpreviewfile = false;
              Toast.fire({
                icon: "warning",
                title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
              });
            }else if(currentfile.editing_status === 'Y'){
              if(currentfile.last_update_name.name_th === 'ฉัน'){
                this.openpreviewfile = true;
              }else{
                this.openpreviewfile = false;
                Toast.fire({
                  icon: "warning",
                  title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
                });
              }
            }else{
              this.openpreviewfile = true;
            }
      } else if (parameter === "previewnewtab") {
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
        // if(checkinet_jv === -1){
          // let checkinet_jv = VueCookies.get("feature_onlyoffice")
          // if(checkinet_jv === 'false'){
          let checkinet_jv = this.feature_onlyoffice;
            if(checkinet_jv === false){
              this.param_preview = "preview";
            }else{
              if(
                // (['04', '05', '06'].includes(currentfile.file_permission_2)) &&
                (currentfile.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.file_type.toLowerCase()) || 
                currentfile.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(currentfile.data_type.toLowerCase()) || 
                currentfile_type_file[currentfile_type_file.length - 1] && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes( currentfile_type_file[currentfile_type_file.length - 1].toLowerCase()))
              ){
                this.param_preview = "editfileonline";
              }else{
                this.param_preview = "preview";
              }
            }
            this.fn_previewnewtab(currentfile);
      } else if (parameter === "sharefilelink") {
        this.opensharelink = true;
      } else if (parameter === "sharelinkupload") {
        this.opensharelinkuploads = true;
      } else if (parameter === "sharefilepeople") {
        this.opensharefile = true;
      } else if (parameter === "keydetail") {
        //this.$emit("opendetailfile")
        this.openkeydetail = true;
      } else if (parameter === "newpreview") {
        this.opennewpreviewfile = true;
      } else if (parameter === "managefile") {
        console.log(this.$route.params.id);
        this.opensetpermissionfile = true;
      } else if (parameter === "relationfile") {
        this.openrelationfile = true;
      } else if (parameter === "createrelationfile") {
        this.opencreaterelationfile = true;
      } else if (parameter === "deleterelationfile") {
        this.$emit("deleterelation", currentfile);
      } else if (parameter === "createpassword") {
        this.opencreatepassword = true;
        // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
        this.$emit("status_createpass", true)
      } else if (parameter === "editpassword") {
        this.openeditpassword = true;
        // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
        this.$emit("status_createpass", true)
      } else if (parameter === "deletepassword") {
        this.opendeletepassword = true;
        // EMIT TO DISABLE AUTO FILL ON SEARCH AND AUTO COMPLETE
        this.$emit("status_createpass", true)
      } else if (parameter === "showinfolder") {
        this.$emit("callparantfolder", currentfile);
      } else if (parameter === "openfolder") {
        console.log("openfile clickclick");
        if (this.file.file_type === "folder") {
          // gotodirectory function
          this.gotodirectory(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
        }
      } else if (parameter === "openfoldernewtab") {
        if (this.file.file_type === "folder") {
          this.gotodirectory_newtab(currentfile.file_id, currentfile.file_type, currentfile.file_department, currentfile);
        }
      } else if (parameter === "setexpired") {
        this.opensetexpired = true;
      } else if (parameter === "editexpired") {
        this.opensetexpired = true;
      } else if (parameter === "cancelexpired") {
        this.opencancelexpired = true;
      } else if (parameter === "copyfile") {
        this.fn_copyfile(currentfile);
      } else if (parameter === "paste") {
        this.fn_pastefile(currentfile);
      } else if (parameter === "tagversion") {
        this.opendialog_tagversion = true;
      } else if (parameter === "editfileonline"){
        this.param_preview = "editfileonline"
        if(currentfile.editing_status === 'S'){
          this.openpreviewfile = false;
          Toast.fire({
            icon: "warning",
            title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          });
        }else if(currentfile.editing_status === 'Y'){
          if(currentfile.last_update_name.name_th === 'ฉัน'){
            this.openpreviewfile = true;
          }else{
            this.openpreviewfile = false;
            Toast.fire({
              icon: "warning",
              title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
            });
          }
        }else{
          console.log("checkinet_jv");
          this.openpreviewfile = true;
        }
      } else if (parameter === "setprofile"){
        this.fn_sendtosettingprofile(currentfile);
      } else if (parameter === "copy_file") { //NEW GENERATION
        this.$emit("closecurrentonly")
        this.fn_newcopyfile(currentfile)
      } else if (parameter === "copyfile_") { // NEW GENERATION
        console.log("ROUTEE ", this.$route)
        this.$emit("closecurrentonly")
        this.fn_copyfile_(currentfile)
      } else if (parameter === "pastefile_") {
        this.$emit("closecurrentonly")
        this.fn_pastefiles(currentfile, "toroot_")
      } else if (parameter === "pastefile_tofolder_") {
        //console.log("Paste file to folder here ")
        this.$emit("closecurrentonly")
        this.fn_pastefiles(currentfile, "tofolder_")
      }

      console.log("this.param_preview",this.param_preview);
    },
    // showparant(currentfile){
    //   console.log("pppppppp",currentfile);
    // // if(currentfile.file_type === "Folder"){
    //  // this.$router.replace({ path: "/directory/" + folderid });
    // // }
    //   // this.parentfolder = this.$route.params.id;
    // },
    fn_previewnewtab(file) {
      let currentfile = JSON.stringify(file);
      if (file.editing_status === 'S') {
        Toast.fire({
          icon: "warning",
          title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
        });
        return
      } else if (file.editing_status === 'Y') {
        if(file.last_update_name.name_th !== 'ฉัน'){
          Toast.fire({
            icon: "warning",
            title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          });
          return
        }
      }
      sessionStorage.setItem("file_preview_newtab", currentfile);
      let route = this.$router.resolve({ path: "/preview" });
      let arrayfile_ = JSON.stringify(this.arrayfile);
      sessionStorage.setItem("arrayfile", arrayfile_);
      window.open(route.href);
    },
    colsedowload() {
      this.opensnackbar = false;
      this.newlist = [];
      this.listdatadowload = [];
    },
    fn_calculate_size(_sizefilebyte) {
      console.log("ขนาดไฟล์เป็น GB", (_sizefilebyte / 1073741824).toFixed(2));
      return parseFloat((_sizefilebyte / 1073741824).toFixed(2));
    },
    async fn_loop_downloading(all_file, zip_name, url_link) {
      let total = 0;
      let res;
      if (all_file.length === 0) {
        this.newlist[this.dataIndex]["value"] = 100;
        console.log(url_link);
        window.location.assign(url_link);
        this.opensnackbar = false;
      } else {
        this.source = CancelToken.source()
        for (let i = 0; i < all_file.length; i++) {
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          
          total += (1 / all_file.length) * 100;

         

          //  setTimeout(async () => {
          //     // try {
          //       res = await this.downloading_folder(
          //       all_file[i]["file_id"],
          //       all_file[i]["location_file"],
          //       zip_name,
          //       all_file[i]["path"],
          //       all_file[i],
          //       this.source.token
          //   );
         
          //     console.log(res);
          //     if (this.newlist[this.dataIndex]["value"] > 99) {
          //       this.newlist[this.dataIndex]["value"] = 99;
          //     } else {
          //       this.newlist[this.dataIndex]["value"] = Math.round(total);
          //     }
          //     this.newpercen = this.newlist[this.dataIndex]["value"];
          //     this.namefile = this.newlist[this.dataIndex]["name"];
          //     console.log(this.percentCompleted);
          //     // } catch (error) {
          //     //     console.error('Error:', error);
          //     // }
          //   }, 2000); 
            res = await this.downloading_folder(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              all_file[i],
              this.source.token
            );
         
          console.log(res);
          if (this.newlist[this.dataIndex]["value"] > 99) {
            this.newlist[this.dataIndex]["value"] = 99;
          } else {
            this.newlist[this.dataIndex]["value"] = Math.round(total);
          }
          this.newpercen = this.newlist[this.dataIndex]["value"];
          this.namefile = this.newlist[this.dataIndex]["name"];
          console.log(this.percentCompleted);

          // if(res.status === "OK" ){

          // }else{
          //   //continue;
          // }
          // console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            console.log("this.listerror",this.listerror);
            if(this.listerror.length == 0 ){
              setTimeout(() => {
                this.newlist[this.dataIndex]["value"] = 100;
                console.log(url_link);
                window.location.assign(url_link);
                this.opensnackbar = false;
              },1000)
            }else{
              // console.log(this.listerror.length);
              let y ;
              for ( y = 0; y < this.listerror.length; y++) {
                if (y === this.listerror.length - 1) {
                  this.newlist[this.dataIndex]["value"] = 100;
                  console.log(url_link);
                  window.location.assign(url_link);
                  this.opensnackbar = false;
                }else{
                  res = await this.downloading_folder_again(
                    this.listerror[y]["file_id"],
                    this.listerror[y]["location_file"],
                    zip_name,
                    this.listerror[y]["path"],
                    this.listerror[y]
                  );
                }
                }


              // console.log("เข้าค่ะ");
              // let y ;
              // for ( y = 0; y < this.listerror.length; y++) {
              //   let res = await this.downloading_folder_again(
              //     this.listerror[y]["file_id"],
              //     this.listerror[y]["location_file"],
              //     zip_name,
              //     this.listerror[y]["path"],
              //     this.listerror[y]
              //   );

              //   }
              //   if (y === this.listerror.length - 1) {
              //     this.newlist[this.dataIndex]["value"] = 100;
              //     console.log(url_link);
              //     window.location.assign(url_link);
              //     this.opensnackbar = false;
              //   }
            }




            //            this.axios.get(url_link, {})
            //  .then((response) => {
            //   console.log(response);
            // });
          }
        }
      }
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
   async downloading_folder_again(file_id, location_file, zip_name, all_file,file,token){
      let url =
        //process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER_MULTI +
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/downloading_file?file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file +
        "&account_id=" +
        this.dataAccountId
      console.log(url);
       await this.axios
          .get(url)
          .then((response) => {
            console.log("response2",response);
            if (response.data.status === "OK") {
                // this.total += Math.floor(100/this.total_allfile);
            } else {
              this.source.cancel();
              Toast.fire({
                icon: "error",
                title: response.data.filename + " " +":" + " " + response.data.errorMessage ,
              });
              this.opensnackbar = false;
              this.btsdownload = false;
        
            }
          })
          .catch((error) => {
            if (this.countCancel === 0) {
              // this.source.cancel();
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
              console.log("err", error);
            }
          });
      // let url =
      //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
      //   "/api/downloading_file?file_id=" +
      //   file_id +
      //   "&location_file=" +
      //   location_file +
      //   "&zip_name=" +
      //   zip_name +
      //   "&path=" +
      //   all_file;

      // console.log(url);
      // return new Promise((resolve, reject) => {
      //   this.axios.get(url, {}).then((response) => {
      //     console.log("response",response);
      //     console.log("testtt", response.data.status);
      //     if(response.data.status === "OK"){          
      //         setTimeout(() => {
      //           resolve({
      //             status: response.data.status,
      //             msg: "",
      //           });
      //         }, 1);
      //     }else{
      //       this.source.cancel();
      //       setTimeout(() => {
      //         this.opensnackbar = false;
      //         }, 3000)
      //         Toast.fire({
      //           icon: "error",
      //           title: "ไม่สามารถดาวน์โหลดนี้ได้",
      //         });
      //     }
      //   });
      // });
    },
    async downloading_folder(file_id, location_file, zip_name, all_file,file,token) {
      console.log("downloading_file file_id",file_id);
      console.log("downloading_file location_file",location_file);
      console.log("downloading_file all_file",all_file);
      let url =
        //process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER_MULTI +
        process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        "/api/downloading_file?file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file +
        "&account_id=" +
        this.dataAccountId
      console.log(url);
       await this.axios
          .get(url)
          .then((response) => {
            console.log("response 1",response);
            if (response.data.status === "OK") {
                // this.total += Math.floor(100/this.total_allfile);
            } else {
              console.log("error 1");
              this.listerror.push(file);
              console.log("this.listerror",this.listerror);
            }
          })
          .catch((error) => {
            if (this.countCancel === 0) {
              // this.source.cancel();
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลดนี้ได้",
              });
              console.log("err", error);
            }
          });
      // let url =
      //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
      //   "/api/downloading_file?file_id=" +
      //   file_id +
      //   "&location_file=" +
      //   location_file +
      //   "&zip_name=" +
      //   zip_name +
      //   "&path=" +
      //   all_file;

      // console.log(url);
      //  return new Promise((resolve, reject) => {
      //   this.axios.get(url, {}).then((response) => {
      //     console.log("response",response);
      //     console.log("testtt", response.data.status);
      //     if(response.data.status === "OK"){          
      //         setTimeout(() => {
      //           resolve({
      //             status: response.data.status,
      //             msg: "",
      //           });
      //         }, 1);
      //     }else{
      //       console.log("error 1");
      //       this.listerror.push(file);
      //       console.log("this.listerror",this.listerror);
      //     }

      //     // }else{
      //     //    return new Promise((resolve, reject) => {
      //     // setTimeout(() => {
      //     //       resolve({
      //     //         status: response.data.status,
      //     //         msg: "",
      //     //       });
      //     //     }, 1000);
      //     //       });
      //     // }
      //   });
      // });
    },

    download_new(file){
      this.filedownload_new = [];
      // console.log("filedownload_new",file);
      this.filedownload_new.push(file);
      this.opendialogmultidownload_new = true;
    },

    //dowload 1 file
    async downloadfile(currentfile) {
      this.listerror =[];
      this.$emit("closecurrentonly");
      console.log("currentfile", currentfile);
      let checkfilesize = this.fn_calculate_size(currentfile.file_size);
      this.btsdownload = true;
      // console.log(currentfile);
      let datadowload = {};

      // console.log(this.listdatadowload);
      // check listdatadowload ว่าซ้ำมั้ย
      // let checkdata = this.listdatadowload.findIndex((v) => v.file_id === currentfile.file_id);
      // if (checkdata != -1) {
      //   Toast.fire({
      //     icon: "error",
      //     title: "พบการดาวน์โหลดซ้ำ กรุณาดาวน์โหลดใหม่อีกครั้ง",
      //   });
      //   // this.snackbardowload = false;
      //   this.opensnackbar = false;
      //   this.listdatadowload = [];
      this.newlist = [];
      // } else {
      this.listdatadowload.push(currentfile);

      // console.log(this.listdatadowload);

      for (let i = 0; i < this.listdatadowload.length; i++) {
        datadowload["fileid"] = this.listdatadowload[i].file_id;
        datadowload["name"] = this.listdatadowload[i].file_name;
        datadowload["value"] = 0;
        // console.log(dat adowload);
      }

      this.newlist.push(datadowload);
      // console.log(this.newlist);

      this.percentCompleted = 0;
      if (currentfile.file_type === "folder") {
        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        //this.btsdownload = true;
        this.opensnackbar = true;
        this.new_list = this.newlist;
        let timeout = 4000;

        let all_file = [];
        let zip_name = "";
        let url_link;
        //test
        let url =
          //process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER_MULTI +
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/request/download_folder?account_id=" +
          this.dataAccountId +
          "&folder_id=" + 
          currentfile.file_id+
          "&domain=box";

        //  let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //   "/api/request/download_folder?account_id=" +
        //   "5af57356a1fb9db9638b87893ba2039a" +
        //   "&folder_id=" +
        //   "44464fbc77f6aaa0f4ebda1790243c2f";

        this.axios
          .get(url, {})
          .then((response) => {
            //console.log(response.data.status);
            if (response.data.status === "OK") {
              // all_file = response.data.all_file
              // zip_name =  response.data.zip_name
              // url_link = response.data.url
              //console.log("fff",url_link);
              //ฟิกtest
            //   response.data.all_file.push({
            //     file_id: "8ba3cc2dc065657e6b7c2a869c200000",
            //     full_path: "Mainfolder/TEST_MOVE_TEST/A2",
            //     location_file: "mapdrive/folder_downloaded/downloading/20b2030ebb3f774e1dce2a1b0561c68b20230517150000",
            //     path: "TEST_MOVE_TEST/A2"
            // })
            setTimeout(() => {
              this.fn_loop_downloading(response.data.all_file, response.data.zip_name, response.data.url);
            },1000)
            
            } else {
              this.btsdownload = false;
              this.opensnackbar = false;
              Toast.fire({
                icon: "error",
                title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
              });
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbar = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
            });
            console.log("err", error);
          });

        // for (let i = 0; i < this.listdatadowload.length; i++) {
        //   this.dataIndex = this.newlist.findIndex((obj) => obj.fileid === this.listdatadowload[i].file_id);
        // }
        // console.log("folder", this.dataIndex);

        // this.opensnackbar = true;
        // this.new_list = this.newlist;
        // let auth = await gbfGenerate.generateToken();
        // this.axios
        // .get(url, {
        //   headers: { Authorization: auth.code },
        //   onDownloadProgress: (progressEvent) => {
        //     // console.log("progressEvent", progressEvent);
        //     let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        //     if (this.newlist[this.dataIndex]["value"] === 95) {
        //       this.newlist[this.dataIndex]["value"] = 95;
        //     } else {
        //       this.newlist[this.dataIndex]["value"] = progresspercent;
        //     }
        //     // console.log("percenfolder", this.newlist[this.dataIndex]["value"]);
        //     this.newpercen = this.newlist[this.dataIndex]["value"];
        //     this.namefile = this.newlist[this.dataIndex]["name"];
        //   },
        //   withCredentials: false,
        //   responseType: "arraybuffer",
        // })
        // .then(response => {
        //   console.log("reponse folder",response);
        //   // ตรวจสอบว่า  API ทำงานถูกต้อง
        //   if (response.statusText === "OK") {
        //     // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
        //     if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
        //        // Backend ไม่ส่งไฟล์มา
        //       this.opensnackbar = false;
        //       let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
        //       Toast.fire({ icon: "error", title: res_error.errorMessage });
        //       } else {
        //         // Backend ส่งไฟล์มา
        //         this.newlist[this.dataIndex]["value"] = 100;
        //         this.btsdownload = false;
        //         let headers = response.headers;
        //         let blob = new Blob([response.data],{type:headers['content-type']});
        //         let link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = currentfile.file_name;
        //         link.click();
        //         setTimeout(() => {
        //           this.opensnackbar = false;
        //         }, 2500)
        //       }
        //   }else{
        //      this.opensnackbar = false;
        //   }
        //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
        //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
        //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        //   // console.log("ดาวน์โหลดเสร็จ",this.newlist);
        //   // console.log("ดาวน์โหลดเสร็จpercen",this.newpercen);
        // }).catch(error => {
        //   this.btsdownload = false;
        //   this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
        //   this.newlist = []; //เคลียร์ array เป็น key แล้ว
        //   this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
        //   Toast.fire({
        //     icon: "error",
        //     title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
        //   });
        //   console.log(error);
        // });
      } else {
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };
        // let url =
        //   process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
        //   "/api/v2/download_file?user_id=" +
        //   this.dataUsername +
        //   "&file_id=" +
        //   currentfile.file_id;
         let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/v1/download_file_s3?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id +
          "&account_id=" +
          this.dataAccountId

        for (let i = 0; i < this.listdatadowload.length; i++) {
          this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.listdatadowload[i].file_name);
        }
        // console.log("file", this.dataIndex);

        // if (checkfilesize < 1.0) {
          console.log(currentfile.status_encrypt);
          if(currentfile.status_encrypt === "Y"){      
            this.opensnackbar = true;
            this.new_list = this.newlist;
            let auth = await gbfGenerate.generateToken();
            console.log("น้อยกว่า 1 GB");
            this.axios
              .get(url, {
                headers: { Authorization: auth.code },
                onDownloadProgress: (progressEvent) => {
                  // console.log("progressEvent", progressEvent);
                  let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                  // console.log(this.percentCompleted);

                  if (this.newlist[this.dataIndex]["value"] === 95) {
                    this.newlist[this.dataIndex]["value"] = 95;
                  } else {
                    this.newlist[this.dataIndex]["value"] = progresspercent;
                  }
                  // console.log("percenfile", this.newlist[this.dataIndex]["value"]);
                  this.newpercen = this.newlist[this.dataIndex]["value"];
                  this.namefile = this.newlist[this.dataIndex]["name"];
                },
                withCredentials: false,
                responseType: "arraybuffer",
              })
              .then((response) => {
                // ตรวจสอบว่า  API ทำงานถูกต้อง
                // if (response.statusText === "OK") {
                if (response.status === 200) {
                  try {
                    let type = currentfile.file_name.split(".");
                    type = type[type.length - 1].toLowerCase();
                    let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                    if (res_error.status === "ER" && type !== "json") {
                      this.opensnackbar = false;
                      Toast.fire({
                        icon: "error",
                        title: res_error.errorMessage,
                      });
                    } else {
                      this.opensnackbar = true;
                      this.newlist[this.dataIndex]["value"] = 100;
                      this.btsdownload = false;

                      let headers = response.headers;
                      let blob = new Blob([response.data], { type: headers["content-type"] });
                      let link = document.createElement("a");
                      link.href = window.URL.createObjectURL(blob);
                      link.download = currentfile.file_name;
                      link.click();
                      setTimeout(() => {
                        this.opensnackbar = false;
                      }, 2500);
                    }
                  } catch (error) {
                    console.log("error", error);
                    this.opensnackbar = true;
                    this.newlist[this.dataIndex]["value"] = 100;
                    this.btsdownload = false;
                    let headers = response.headers;
                    let blob = new Blob([response.data], { type: headers["content-type"] });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentfile.file_name;
                    link.click();
                    setTimeout(() => {
                      this.opensnackbar = false;
                    }, 2500);
                  }
                  // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                  // if (
                  //   // parseInt(response.headers["content-length"]) < 700 &&
                  //   response.headers["content-type"] === "application/json"
                  // ) {
                  //   // Backend ไม่ส่งไฟล์มา
                  //   console.log("response < 1000", response);
                  //   this.opensnackbar = false;
                  //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  //   console.log("responseerror", res_error);
                  //   Toast.fire({
                  //     icon: "error",
                  //     title: res_error.errorMessage,
                  //   });
                  //   // Backend ส่งไฟล์มา
                  // } else {
                  //   // console.log("response โหลดได้", response);
                  //   // console.log(parseInt(response.headers["content-length"]));
                  //   this.opensnackbar = true;
                  //   this.newlist[this.dataIndex]["value"] = 100;
                  //   this.btsdownload = false;

                  //   let headers = response.headers;
                  //   let blob = new Blob([response.data], { type: headers["content-type"] });
                  //   let link = document.createElement("a");
                  //   link.href = window.URL.createObjectURL(blob);
                  //   link.download = currentfile.file_name;
                  //   link.click();
                  //   // const blob = new Blob([response.data]);
                  //   // const content = response.headers["content-type"];
                  //   // saveAs(blob, currentfile.file_name);
                  //   setTimeout(() => {
                  //     this.opensnackbar = false;
                  //   }, 2500);
                  // }
                } else {
                  this.opensnackbar = false;
                  Toast.fire({
                    icon: "error",
                    title: this.$t("toast.cannotconnectonebox"),
                  });
                }
                // this.opensnackbar = false;
                this.listdatadowload = []; //เคลียร์listที่เลือกดาวน์โหลด
                this.newlist = []; //เคลียร์ array เป็น key แล้ว
                this.newpercen = []; //เคลียร์ค่าเปอร์เซ็น
                // console.log("ดาวน์โหลดเสร็จfile",this.newlist);
                // console.log("ดาวน์โหลดเสร็จpercenfile",this.newpercen);
              })
              .catch((error) => {
                this.btsdownload = false;
                this.opensnackbar = false;
                Toast.fire({
                  icon: "error",
                  title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้",
                });
                console.log("err", error);
              });
          }else{
            console.log("เข้า status_encrypt = N");
            this.axios.get(url)
            .then(response => {
              console.log("=",response);
              // แก้เพราะดาวน์โหลดละ error
              if(response.data.url === undefined){
                window.location.href = response.config.url;
              }else{
                window.location.href = response.data.url;
              }
            }).catch(console.error)

          }
        // } else {
        //   this.btsdownload = true;
        //   this.opensnackbardownloadfile = true;
        //   let timeout = checkfilesize * 4000;
        //   window.location.assign(url);
        //   setTimeout(() => {
        //     this.opensnackbardownloadfile = false;
        //   }, timeout);
        // }
      }
      // }
    },
    closeDialogEditname() {
      this.$emit("closecurrent");
    },
    //copyfile
    fn_copyfile(currentfile) {
      console.log("เข้าcopyfile", currentfile);
      if (currentfile.file_type !== "folder") {
        sessionStorage.setItem("filecopy", JSON.stringify([currentfile]));
        Toast.fire({
          icon: "success",
          title: "คัดลอกไฟล์" + currentfile.file_name,
        });
      } else {
        Toast.fire({
          icon: "error",
          title: "กรุณาเลือกไฟล์ที่ต้องการคัดลอก",
        });
      }
    },
    fn_newcopyfile (currentfile) {
      if(this.imageHeight < 500){
        this.$emit("gatherfiles_copypaste_device", currentfile)
      } else {
        this.$emit("gatherfiles_copypaste", currentfile)
      }
      
    },
    fn_copyfile_ (currentfile) { // BY CLICK ขวา
      this.$emit("copied_file", {files_: [currentfile.file_id]})
    },
    async fn_pastefiles (currentfile, type) {
      // Read Clipboard 
      try { // ดักไว้ก่อนน
        let ReadClipBoard =  await navigator.clipboard.readText()
        var JSONParse_ClipArray = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(ReadClipBoard, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, })))
        if(this.imageHeight < 500){
            this.$emit("pasted_file_device", {files_: JSONParse_ClipArray.array_files, to_: {type: "FOLDER", id: currentfile.file_id }})
        } else {
          if(type === "toroot_"){
            this.$emit("pasted_file", {files_: JSONParse_ClipArray.array_files, to_: {type: "ROOT", id: ""} })
          } else { // toFolder ...
            console.log("ไปยัง folder ", JSONParse_ClipArray)
            this.$emit("pasted_file", {files_: JSONParse_ClipArray.array_files, to_: {type: "FOLDER", id: currentfile.file_id } })
          }
        }
      } catch (err) {
        return
      }
    },




    //วางไฟล์
    async fn_pastefile(currentfile) {
      let data_copy = sessionStorage.getItem("filecopy");
      let data_paste = JSON.parse(data_copy);
      console.log("data_paste", data_paste);
      console.log("currentfile", currentfile);

      for (let i = 0; i < data_paste.length; i++) {
        this.listfilecopy.push({
          data_type: data_paste[i].file_type === "folder" ? "folder" : "file",
          data_id: data_paste[i].file_id,
        });
      }
      console.log("listfilecopy", this.listfilecopy);
      if (this.$router.currentRoute.path === "/mydrive") {
        if (currentfile.file_type === "folder") {
          let payload = {
            account_id: this.dataAccountId,
            folder_id: currentfile.file_id,
            copied: this.listfilecopy,
          };
          console.log("payloadcopyfile", payload);
          let auth = await gbfGenerate.generateToken();
          console.log("auth", auth);
          let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
            headers: { Authorization: auth.code },
          });
          console.log(resp);
          try {
            if (resp.data.status === "OK") {
              // this.createprogress = false;
              Toast.fire({
                icon: "success",
                title: "วาง",
              });
              this.$emit("reload");
            } else {
              this.createprogress = false;
              Toast.fire({
                icon: "error",
                title: resp.data.errorMessage,
              });
            }
          } catch (err) {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: err,
            });
          }
        } else {
          let payload = {
            account_id: this.dataAccountId,
            folder_id: this.dataAccountActive.directory_id,
            copied: this.listfilecopy,
          };
          console.log("payloadcopyfile", payload);
          let auth = await gbfGenerate.generateToken();
          console.log("auth", auth);
          let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
            headers: { Authorization: auth.code },
          });
          console.log(resp);
          try {
            if (resp.data.status === "OK") {
              // this.createprogress = false;
              Toast.fire({
                icon: "success",
                title: "วาง",
              });
              this.$emit("reload");
            } else {
              this.createprogress = false;
              Toast.fire({
                icon: "error",
                title: resp.data.errorMessage,
              });
            }
          } catch (err) {
            console.log(err);
            Toast.fire({
              icon: "error",
              title: err,
            });
          }
        }
      } else {
        let payload;
        if (currentfile.file_type === "folder") {
          payload = {
            account_id: this.dataAccountId,
            folder_id: currentfile.file_id,
            copied: this.listfilecopy,
          };
        } else {
          payload = {
            account_id: this.dataAccountId,
            folder_id: this.$route.params.id,
            copied: this.listfilecopy,
          };
        }
        console.log("payloadcopyfile", payload);
        let auth = await gbfGenerate.generateToken();
        console.log("auth", auth);
        let resp = await axios.post(process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/paste_data", payload, {
          headers: { Authorization: auth.code },
        });
        console.log(resp);
        try {
          if (resp.data.status === "OK") {
            // this.createprogress = false;
            Toast.fire({
              icon: "success",
              title: "วาง",
            });
            this.$emit("reload");
          } else {
            this.createprogress = false;
            Toast.fire({
              icon: "error",
              title: resp.data.errorMessage,
            });
          }
        } catch (err) {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: err,
          });
        }
      }
    },
    //แก้ไขและล้อกไฟล์
    async fn_editfile(currentfile) {
      this.percent = 0;
      this.btsdownload = true;
      console.log("currentfile fn_editfile", currentfile);
      console.log("dataAccountId", this.dataAccountId);
      console.log("user_id", this.dataCitizenProfile.user_id);
      let payload = {
        file_id: currentfile.file_id,
        account_id: this.dataAccountId,
      };
      console.log("payload fn_editfile", payload);
      this.opensnackbarmultidownload = true;
      // this.opensnackbar = true;
      this.namefile = currentfile.file_name;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        onDownloadProgress: (progressEvent) => {
          console.log("progressEvent", progressEvent);
          let progresspercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          if (progresspercent >= 95) {
            this.percentCompleted = 100;
          } else {
            this.percentCompleted = progresspercent;
          }
          this.percent = this.percentCompleted;
          console.log("this.percent", this.percent);
        },
        withCredentials: false,
        responseType: "arraybuffer",
      };
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_and_lock", payload, config)
        .then((response) => {
          // if (response.statusText === "OK") {
          if (response.status === 200) {
            console.log("response.data++", response.data);
            if (
              parseInt(response.headers["content-length"]) < 700 &&
              (response.headers["content-type"]) === "application/json"
            ) {
              console.log("response < 1000", response);
              this.opensnackbarmultidownload = false;
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              console.log("responseerror", res_error);
              Toast.fire({
                icon: "error",
                title: res_error.errorMessage,
              });
            } else if (response.data.stutus === "ER") {
              this.btsdownload = false;
              this.opensnackbarmultidownload = false;
              this.closeDialogEditname();
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            } else {
              console.log(response);
              this.percentCompleted = 100;
              this.btsdownload = false;
              setTimeout(() => {
                this.opensnackbarmultidownload = false;
                //  this.opensnackbar = false;
              }, 2500);
              const blob = new Blob([response.data]);
              const content = response.headers["content-type"];
              saveAs(blob, currentfile.file_name);
              // this.clearmultipledownload();
              this.closeDialogEditname();
              // this.$emit("reload");
            }
          }
        })
        .catch((error) => {
          // this.$store.dispatch("show_loading", false);
          this.btsdownload = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
          console.log(error);
        });
    },
    //ปลดล็อกไฟล์
    async fn_unlock(currentfile) {
      let payload = {
        file_id: currentfile.file_id,
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/unlock_editing_file", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: this.$t("lockfile.unlockfilesuccess"),
            });
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ": " + response.data.errorMessage,
            });
          }
          this.$emit("reload");
        })
        .catch((error) => {
          console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    },
    // สร้างไฟล์ ocr
    async fn_createfileOCR(currentfile) {
      let payload = {
        file_id: currentfile.file_id,
        account_id: this.dataAccountId,
      };
      this.$emit("toggle_loading_ocr");
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_UPLOAD_FILE + "/api/v1/ocr/image", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: this.$t("toast.text.ocr"),
            });
            this.$emit("toggle_loading_ocr");
            this.$emit("reload");
          } else {
            this.$emit("toggle_loading_ocr");
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.$emit("toggle_loading_ocr");
          console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
        });
    }
  },
};
</script>

<style>
/* @import url("https://fonts.googleapis.com/css?family=Sarabun:300,600&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;600&display=swap");
#app_currentrightclick {
  font-family: "Sarabun", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.v-progress-circular {
  margin: 1rem;
}
.menu-content {
  z-index: 99999999 !important;
}
</style>
